import { motion, useScroll, useTransform } from "framer-motion";
import React, { useEffect, useState } from "react";
export default function Hero() {
    const data = ["TRAINER", "MOTIVATOR", "NUTRITIONIST"];
    const [label, setlabel] = useState("TRAINER");
    const { scrollY } = useScroll();
    const y = useTransform(scrollY, [0, 200], [0, 50]);
    
  let i1 = 0;
  useEffect(() => {
    const timer = setInterval(() => {
      i1 = (i1 + 1) % data.length;
      setlabel(() => data[i1]);
    }, 3000);
    return () => clearInterval(timer);
  }, []); // Add label to dependencies
  return (
    <div className="w-[100vw] relative max-md:h-[30rem]  md:h-[45rem] flex justify-center items-center overflow-x-hidden">
      <div className="w-[100%] max-md:mt-[5rem] relative h-[100%]  max-w-[1400px] px-[5%] py-[4rem] flex  ">
         
            <img
              src={require("./Assets/JORGEFRANKLIN.png")}
              alt=""
              className="absolute  -z-10 left-[5%] top-[25%]"
            />
            <img
          
              src={require("./Assets/iStock-655555254-e1614627527508.jpg")}
              className="md:w-[50%] 
                 right-0 md:bottom-[-15%]  absolute -z-20"
              alt=""
            />


        <div className="w-[100%] flex justify-center items-center  h-[100%]">
            <motion.div 
            style={{y:y}}
        className="md:w-[70%] md:absolute  md:bottom-[10%] flex flex-col gap-8 ">
              <h1 className="text-xl font-bold text-white text-left uppercase">
                With Jorge Franklin
              </h1>
              <h1 className=" text-white font-[700] hero max-md:text-[50px] max-md:leading-[50px] md:text-[98px] md:leading-[0.9em]">
                TRAIN WITH THE <br className="max-md:hidden" />
                BEST PERSONAL <br className="max-md:hidden"  />
                <span className="inline-flex tc">
                  {label == "TRAINER" &&
                    "TRAINER".split("").map((d, i) => (
                      <motion.span key={i}
                        initial={{ rotateY: -90, opacity: 0 }}
                        animate={{ rotateY: 0, opacity: 1 }}
                        transition={{ duration: 1, delay: 0.05 * i }}
                        className=""
                      >
                        {d}
                      </motion.span>
                    ))}
                  {label === "MOTIVATOR" &&
                    "MOTIVATOR".split("").map((d, i) => (
                      <motion.span
                      key={i}
                        initial={{ rotateY: -90, opacity: 0 }}
                        animate={{ rotateY: 0, opacity: 1 }}
                        transition={{ duration: 1, delay: 0.05 * i }}
                        className=""
                      >
                        {d}
                      </motion.span>
                    ))}
                  {label === "NUTRITIONIST" &&
                    "NUTRITIONIST".split("").map((d, i) => (
                      <motion.span key={i}
                        initial={{ rotateY: -90, opacity: 0 }}
                        animate={{ rotateY: 0, opacity: 1 }}
                        transition={{ duration: 1, delay: 0.05 * i }}
                        className=""
                      >
                        {d}
                      </motion.span>
                    ))}
                </span>
              </h1>

            </motion.div>
       
        </div>
      </div>
    </div>
  );
}
