import { motion } from 'framer-motion'
import React, { useState } from 'react'
import img from '../Assets/shape-green.svg'
export default function Personal() {
    const [move, setMove] = useState(false);
  return (
    <div className='w-[100vw] h-[100%] relative flex justify-center hero items-center '>
        <div className='max-w-[1400px] flex-col w-[100%]  pt-[3rem] pb-[3rem] flex justify-center items-center'>
           <h1 className="text-4xl font-bold leading-[1.7rem] text-center text-white uppercase">Personal Training</h1>
           <div 
            className='md:pt-[8rem] max-md:pt-[3rem] w-[100%] relative'>
        <motion.img animate={{x:move?30:0}} transition={{duration:0.2,type:'tween'}} 
        
        src={img} alt="web agency"
         className='absolute top-[0.66rem] max-md:hidden right-[6rem]' />
                <img onMouseEnter={()=>setMove(()=>true)} onMouseLeave={()=>setMove(()=>false)}
                 src={require('../Assets/iStock-1063813258-scaled.jpg')} 
                 className='w-[100%] md:h-[25rem] object-cover' alt="" />
           </div>
            <div   className='md:w-[70%] px-[5%] text-2xl max-md:mt-[1rem] tracking-wider md:mt-[6rem] font-[400] text-white'>
                <p> You and your dedicated personal trainer will create a plan that’s tailored to your goals—and together, you’ll work to unlock the results you want. At every step of the way, you’ll be driven by a passionate trainer using the latest science during in-club sessions. </p>
                <div className="flex items-center mt-[2rem] gap-1 hover:underline">
              <svg
                className="-rotate-90"
                xmlns="http://www.w3.org/2000/svg"
                height="40"
                viewBox="0 0 330 330"
                width="50"
              >
                <path
                  d="m330 0-165 160.742407-165-160.742407v168.205614l165 161.794386 165-161.794386z"
                  fill="#d6fb00"
                  fill-rule="evenodd"
                ></path>
              </svg>
              <h1 className="text-xs uppercase font-[900]">Learn more</h1>
            </div>
            </div>
            
        </div>
      
    </div>
  )
}
