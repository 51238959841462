import React from 'react'
import { FaFacebook, FaInstagram, FaYoutube } from 'react-icons/fa'
import logo from '../Assets/283081577_388067659916237_5236072610395853784_n.jpg'
export default function Footer() {
  return (
    <footer className='bg-white w-[100vw] flex justify-center items-center overflow-hidden'>
      <div className="w-[100%] flex flex-col gap-20 max-w-[1400px] max-md:px-[5%] md:px-[10%] max-md:pt-[3rem] md:pt-[6rem] pb-[2rem]">
        <div className='w-[100%] gap-5 md:flex max-md:flex max-md:flex-col md:items-center md:justify-between'>
           
           <div className='md:flex max-md:flex max-md:flex-col md:gap-10 max-md:gap-5'>
             <img src={logo}
            className='w-[6rem]'
            alt="" />
            <div className='flex flex-col'>
                <h1 className="text-md">
                    
450 NW Couch Street, Portland, Oregon 97209


                </h1>
                <span className='underline'>
                + (123) 1800-567-8990
                </span>
            </div>
                <div className='text-sm font-semibold md:ml-[3rem]'>
                Every day: 9:00 – 22:00 <br/>
                Sat – Sun: 8:00 – 21:00
                </div>
            </div>
            <div className=" flex  md:items-center md:justify-center gap-5 text-[25px]">
                <FaInstagram className='hover:text-[#d6fb00] transition-all cursor-pointer'/>
                <FaFacebook className='hover:text-[#d6fb00] transition-all cursor-pointer'/>
                <FaYoutube className='hover:text-[#d6fb00] transition-all cursor-pointer'/>
                </div>
        </div>

        <form className="w-[100%] flex items-center border-[1rem] border-[#e2e2e2]">
            <input type="text" placeholder='Enter You Email' className='w-[80%] outline-none transition-all hover:border-[1px] border-black py-2 px-2' required/>
            <button className='text-lg font-[500] transition-all hover:bg-[#d6fb00] hover:underline w-[20%] text-center h-[100%] py-2'>Sing Up</button>
        </form>
        <div className='md:flex max-md:flex max-md:flex-col w-[100%] md:justify-between md: max-md:gap-5items-center '>
            <div className='md:flex max-md:flex max-md:flex-col gap-5'>
                <h1 className='text-sm cursor-pointer hover:underline text-gray-600'>
                Classes
                </h1>
                <h1 className='text-sm cursor-pointer hover:underline text-gray-600'>
                Personal Training

                </h1>
                <h1 className='text-sm cursor-pointer underline text-gray-600'>
                About
                </h1>
                <h1 className='text-sm cursor-pointer hover:underline text-gray-600'>
                Community
                </h1>
            </div>
            <div className='md:flex max-md:flex max-md:flex-col max-md:mt-[2rem] gap-3 font-semibold '>
                <h1 className='text-xs hover:underline cursor-pointer'>Copyright © 2024
                Vamtam Themes</h1>
                <h1 className='text-xs hover:underline cursor-pointer'>Privacy Policy</h1>
                <h1 className='text-xs hover:underline cursor-pointer'>Terms & Conditions</h1>
            </div>

        </div>

      </div>
    </footer>
  )
}
