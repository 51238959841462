import React, { useState } from 'react'
import img from '../Assets/shape-green.svg'
import { motion } from 'framer-motion'
export default function About({move,setMove}) {
  return (
    <div className='w-[100vw] pb-[8rem] h-[100%] border-t-[1px] border-gray-200 bg-white text-black relative flex justify-center hero items-center '>
    <motion.img animate={{x:move?30:0}} transition={{duration:0.2,type:'tween'}} src={img}
     alt="web agency" className='absolute top-[-7.3rem] right-[6rem] max-md:hidden' />
    <div className='max-w-[1400px] flex-col w-[100%]  pt-[3rem] flex justify-center items-center'>
        <div onMouseEnter={()=>setMove(()=>true)} onMouseLeave={()=>setMove(()=>false)} 
         className='xl:w-[70%] lg:w-[90%] w-[100%] px-[5%]  grid md:grid-cols-3 max-md:grid-cols-1 gap-10  '>
            <div className='flex flex-col gap-4 '>
                <h1 className="text-xl font-semibold">
                Location:
                </h1>
                <p>450 NW Couch Street,<br/>
                Portland, Oregon 97209</p>
            </div>
            <div className='flex flex-col gap-4 '>
                <h1 className="text-xl font-semibold">
                Email us:
                </h1>
                <p>name@email.com.</p>
            </div>
            <div className='flex flex-col gap-4 '>
                <h1 className="text-xl font-semibold">
                Call us:
                </h1>
                <p>+ (123) 1800-567-8990</p>
            </div>
            
        </div>
       
    </div>
  
</div>
  )
}
