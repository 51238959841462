import React from 'react'
import Header from './Header'
import Hero from './Hero'
import About from './About'
import About1 from './About1'
import Experience from './Experience'
import Digital from './Digital'
import Training from './Training'
import Tsetinomial from './Tsetinomial'
import Articals from './Articals'
import Alabam from './Alabam'
import Footer from './Footer'

export default function Home() {
  return (
    <>
    <Header/>
    <Hero/>
    <About/>
    <About1/>
    <Experience/>
    <Digital/>
    <Training/>
    <Tsetinomial/>
    <Articals/>
    <Alabam/>
    <Footer/>
     </>
  )
}
