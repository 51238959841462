import { motion } from 'framer-motion'
import React from 'react'
export default function Artical({img,name,date, setCard, card}) {
  return (
    <div onMouseEnter={()=>setCard(()=>true)}
    onMouseLeave={()=>setCard(()=>false)}
    className='flex flex-col gap-5'>
                        <div className='w-[100%] h-[23rem] overflow-hidden'>
                        <motion.img
                        animate={{scale:card?1.2:1}}
                        transition={{duration:0.7}}
                        src={img}
                        className='w-[100%] h-[100%] object-cover' alt="" />
                        </div>
                        <h1 className={`${card && 'underline'} text-2xl leading-[2rem] tracking-wider font-bold uppercase`}>
                        {name}
                        </h1>
                        <p className='text-sm'>{date}</p>

                    </div>
  )
}
