import React from 'react'

export default function WorkOut() {
  return (
    <div className='w-[100vw] h-[100%] hero  bg-white text-black flex justify-center overflow-hidden items-center'>
      <div className='w-[100%] max-w-[1400px] max-md:px-[5%] md:px-[10%] py-[5rem]'>
        <div className="w-[100%] flex flex-col gap-10">

      <h1 className='md:text-[60px] max-md:text-4xl uppercase md:leading-[55px] font-bold'>
      Workouts Wherever
      </h1>
      <p className='text-2xl font-[300]'>
      At-home or on-the-go, practice wherever and whenever you want. Our virtual, holistic lifestyle coaching and training sessions are designed to activate, stretch, move and strengthen your body. Our Trainers connect with you for coaching sessions customized to deliver the inspiration and motivation you need to reach your goals.
      </p>
        </div>
      </div>
    </div>
  )
}
