import React, { useState } from 'react'
import { BiMenuAltLeft } from "react-icons/bi";
import { MdDoubleArrow } from 'react-icons/md';
import Menu from './UI/Menu';
import logo from './Assets/283081577_388067659916237_5236072610395853784_n-removebg-preview.png'
export default function Header() {
 const [toggle, setToggle]= useState(false)
  return (
    <div style={{zIndex:9999}} className='bg-[#191919]  w-[100vw] z-9999 flex  justify-center items-center py-4 px-[5%] text-white overflow-hidden fixed top-0'> 
      <div className='max-w-[1400px] w-[100%] relative '>
            <div className='w-[100%] flex justify-between items-center'>
                <div onClick={()=>setToggle(()=>true)} className='flex gap-1 cursor-pointer items-center hover:text-[#d6fb00]'>
                    <BiMenuAltLeft className=' text-[2rem]  transition-all'/>
                    <h1 className="uppercase cursor-pointer text-md font-bold hover:underline transition-all">Menu</h1>
                    
                </div>
                <div className='absolute left-[46.5%] top-[6px] flex justify-center items-center h-[100%]'>
                    <img src={logo} 
                    className='w-[6rem]'
                    alt="" />
                </div>
                <div>
                  <a href="/contact">
                    <button className='bg-[#d6fb00] px-4 py-3 flex gap-1 text-black items-center'>
                      
                    <MdDoubleArrow  className='text-[2rem]'/>
                    <h1 className='text-xs font-bold uppercase tracking-wider hover:underline text-black'>Join now</h1>
                    </button>
                  </a>
                </div>
            </div>
      </div>
     <Menu toggle={toggle} setToggle={setToggle}/>
    </div>
  )
}
