import React from 'react'
import { FaAngleDoubleRight } from 'react-icons/fa'

export default function Banner() {
  return (
    <div className='w-[100%] hero mt-[5rem] max-md:h-[25rem] md:h-[35rem]  flex justify-center items-center relative overflow-hidden'>
        <img src={require('../Assets/digitalbanner.jpg')}
        className=' absolute top-0 w-[100%] h-[100%] -z-10 object-cover'
        alt="" />
        <div className="max-w-[1400px] relative md:w-[70%] flex flex-col justify-center  h-[100%] px-[5%]  ">
          <h1 className='text-white max-lg:text-6xl lg:text-[100px] lg:leading-[80px] uppercase font-bold hero'>
          Digital <br/> Coaching
          </h1>
          <div className='bg-yellow-400 absolute bottom-0 w-fit text-black hover:underline transition-all flex items-center gap-1 px-[1.5rem] py-2'>
          <FaAngleDoubleRight className='text-[40px]' /> <h1 className='text-sm uppercase font-semibold'>JOIN NOW</h1>
          </div>
        </div>
      
    </div>
  )
}
