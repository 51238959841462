import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./NEW/Home";
import Index from "./NEW/About/index";
import Indexs from "./NEW/Contact/Index";
import IndexB from "./NEW/Blog/Index";
import IndexD from "./NEW/BlogsDetails/IndexD";
import IndexC from "./NEW/classes/IndexC";
import IndexCl from "./NEW/Class/IndexCl";
import Group from "./NEW/Group/Group";
import Digital from "./NEW/DigitalMain/DIgital";
import Personal from "./NEW/Personal/Personal";

function App() {
  return (
    <div>
   <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/about" element={<Index/>}/>
        <Route path="/contact" element={<Indexs/>}/>
        <Route path="/blogs" element={<IndexB/>}/>
        <Route path="/blog/:id" element={<IndexD/>}/>
        <Route path="/classes" element={<IndexC/>}/>
        <Route path="/class/outdoor" element={<IndexCl/>}/>
        <Route path="/class/group" element={<Group/>}/>
        <Route path="/class/digital" element={<Digital/>}/>
        <Route path="/class/personal" element={<Personal/>}/>

      </Routes>
    </BrowserRouter>
  </div>
  );
}

export default App;
