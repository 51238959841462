import React, { useState } from 'react'
import img1 from '../Assets/iStock-1040500828.jpg'
import img2 from '../Assets/iStock-1040499306.jpg'
import img3 from '../Assets/iStock-1040500914.jpg'
import img4 from '../Assets/iStock-1040495364.jpg'
import Teams from '../UI/Team'
import { FaAngleDoubleRight } from 'react-icons/fa'
export default function Team() {
    const [team,setTeam]=useState(false)
    const [team2,setTeam2]=useState(false)

    const [team3,setTeam3]=useState(false)
    const [team4,setTeam4]=useState(false)

  return (
    <section className='w-[100vw] hero text-white flex justify-center items-center overflow-hidden '>
      <div className='max-w-[1400px] w-[100%] px-[5%] py-[6rem] flex items-center justify-center flex-col '>
        <div className="w-[100%] flex flex-col gap-10 ">
            <h1 className="text-4xl text-center font-bold uppercase ">
            Train Only With Real <br className='max-md:hidden'/> Professionals
            </h1>
            <div className='grid lg:grid-cols-4 max-lg:grid-cols-1 gap-7 md:mt-[10rem]'>
                <Teams card={team} setCard={setTeam} style={0} img={img1} head={'Charlie Andrews'}
                p={`Boxing`}
                />
                <Teams card={team2}  setCard={setTeam2} style={`md:mt-[-7rem]`} img={img2} head={'Daisy Newman'} 
                p={`Yoga`}
                />
                <Teams card={team3} setCard={setTeam3} style={'md:mt-[-4rem]'} img={img3} head={'Stella Adkins'} 
                p={`Pilates`}/>
                <Teams card={team4} setCard={setTeam4} style={0} img={img4} head={'Alan McBride'}
                p={`crossfit`}
                />
            </div>
        </div>
        <div className='flex items-center hover:underline md:mt-[6rem] max-md:mt-5 gap-2 text-[#d6fb00]'>
        <FaAngleDoubleRight  className="text-[50px]"/> <h1 className='text-sm uppercase tracking-wider font-semibold'>
        Meet our trainers</h1>
        </div>
      </div>
    </section>
  )
}
