import { motion } from 'framer-motion'
import React from 'react'
export default function Blog({card,setCard,img,h,d,p}) {
  return (
    <a href="/blog/1">
    <div onMouseEnter={()=>setCard(()=>true)} 
    onMouseLeave={()=>setCard(()=>false)}
    className='flex border-[1px] overflow-hidden flex-col gap-5 items-center justify-center'>
            <div className='w-[100%] h-[120rem overflow-hidden'>
        
        <motion.img
        animate={{scale:card?1.2:1}}
        transition={{duration:0.7,type:'tween'}}
        src={img} 
        className='w-[100%] object-cover h-[100%]' alt="" />
        </div>
        <div className="w-[85%] pb-[2rem] flex flex-col gap-3">
            <h2 className={`${card && 'underline transition-all'} text-2xl capitalize font-semibold`}>{h}</h2>
            <span className='text-xs'>{d}</span>
            <p className="text-sm">{p}</p>
        </div>
    </div>
    </a>
  )
}
