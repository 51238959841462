import React from 'react'
import Header from '../Header'
import Banner from './Banner'
import About from './About'
import WorkOut from './WorkOut'
import How from './How'
import Alabam from '../Alabam'
import Footer from '../About/Footer'
import PostRequest from './Resquest'
import Join from './Join'


export default function DIgital() {
  return (
    <div>
      <Header/>
      <Banner/>
      <About/>
      <WorkOut/>
      <How/>
      <Join/>
      <Alabam/>

      <Footer/>
   
    </div>
  )
}
