import React, { useState } from 'react'
import img from '../Assets/shape-green.svg'
import { motion } from 'framer-motion'
import Outdoor from '../classes/Outdoor'
export default function About({move,setMove}) {
 
  return (
    <div className='w-[100vw] h-[100%] relative flex justify-center hero items-center '>
        <motion.img animate={{x:move?30:0}} transition={{duration:0.2,type:'tween'}} src={img} 
        alt="web agency" className='absolute top-[-7.3rem] max-lg:hidden right-[6rem]' />
        <div className='max-w-[1400px] flex-col w-[100%]  pt-[3rem] flex justify-center items-center'>
            <div onMouseEnter={()=>setMove(()=>true)} onMouseLeave={()=>setMove(()=>false)}
              className='xl:w-[60%] md:w-[80%] px-[5%] text-2xl font-[500] text-white'>
                <p>
                We set the standard for quality in group fitness training. The variety of programs, value and level of quality are unmatched in the neighborhood. We may be an old school gym, but we are with the times and provide contemporary classes in various disciplines, including Yoga, Pilates and Boxing. </p>
            </div>
            <div className=' w-[100%] mt-[0rem]'>
                <Outdoor/>
            </div>
        </div>
      
    </div>
  )
}
