import React from 'react'
import { FaAngleDoubleRight } from 'react-icons/fa'

export default function Contact() {
  return (
    <div className='w-[100vw] flex justify-center items-center hero text-white overflow-hidden '>
      <div className="max-w-[1400px] w-[100%] md:px-[10%] max-md:px-[5%] py-[3rem] flex justify-center items-center hero">
        <div className="md:w-[80%] max-md:w-[100%] flex flex-col gap-3">
            <h1 className="text-lg font-semibold ">
            Send us a message:
            </h1>
             <form className='w-[100%] flex flex-col ' >
                <div className="w-[100%] grid md:grid-cols-3 max-md:grid-cols-1 gap-5">
                    <input type="text" placeholder='Name'
                    className='outline-none bg-white px-[2rem] py-2' required/>
                    <input type="email" placeholder='example@gmail.com'
                    className='outline-none bg-white px-[2rem] py-2' required/>
                    <input type="text" placeholder='Enter subject here'
                    className='outline-none bg-white px-[2rem] py-2' required/>
                </div>
                <div className=" w-[100%] mt-5">
                    <textarea name="message" placeholder='Message' rows={5} className='outline-none px-5 py-4
                     w-[100%]' id="" required></textarea>
                </div>
                <button className='w-fit flex items-center gap-3 hover:underline mt-4'>
                <FaAngleDoubleRight className='text-[3rem]' /> <h1 className="text-sm uppercase font-bold">
                    send Now
                </h1>
                </button>
             </form>
        </div>
      </div>
    </div>
  )
}
