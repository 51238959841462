import { motion } from 'framer-motion'
import React from 'react'
export default function Faqs({one,setOne,h,p}) {
  return (
    <div className={`w-[100%] py-[1rem] overflow-hidden border-b-[1px] border-gray-200 flex flex-col`}>
                    <div onClick={()=>setOne(()=>!one)} className='flex cursor-pointer w-[100%] justify-between items-center'>
                        <h1 className='text-lg font-semibold'>{h}</h1>
                        <span className='font-semibold text-5xl flex items-center justify-center'>{one?'-':'+'}</span>
                    </div> 
                    <motion.p animate={{height:one?'100%':0,
                        display:one?'flex':'none',
                        opacity:one?1:0
                    }} transition={{duration:0.3,type:'tween'}} className={``}>
                        {p}
                            </motion.p>

                </div>
  )
}
