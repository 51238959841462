import React from 'react'
import { FaFacebook, FaInstagram, FaYoutube } from 'react-icons/fa'
import { MdOutlineDoubleArrow } from 'react-icons/md'

export default function Footer() {
  return (
    <div className='w-[100vw] flex justify-center items-center overflow-hidden text-white'>
        <div className="max-w-[1350px] px-[5%] pt-[1rem] pb-[2rem] w-[100%]">
            <div className="w-[100%] flex md:justify-center md:items-center flex-col max-md:gap-10 md:gap-24">
                <h1 className="md:text-[60px] max-md:text-[40px] max-md:leading-[35px] max-md:mt-[3rem] uppercase md:leading-[55px] md:text-center font-bold">
                Let's Get <br/>
                Started
                </h1>
                <div className='w-[100%] lg:flex max-lg:flex max-lg:flex-col md:ml-[10%] md:justify-center gap-10 md:items-center '>
                    <div className='flex flex-col gap-5 lg:items-end lg:text-right'>
                        <h1 className='text-2xl font-bold cursor-pointer hover:text-[#e3ee46]'>+ (123) 1800-567-8990</h1>
                        <p className='text-lg'> name@youremail.com</p>
                    </div>
                    <div  className='border-l-[1px] border-gray-600 h-[6rem] max-lg:hidden'/>
                    <div className='text-black flex gap-3 max-lg:w-fit items-center bg-[#ecfa23] px-6 py-4'>
                    <MdOutlineDoubleArrow className='text-[40px]' /> <h1 className='text-sm font-bold text-black uppercase tracking-wide'>Get free consultation</h1>
                    </div>
                    <div  className='border-l-[1px] border-gray-600 h-[6rem] max-lg:hidden '/>
                    <div className='flex flex-col gap-5 items-start text-left'>
                        <h1 className='text-2xl font-bold cursor-pointer hover:text-[#e3ee46]'>Main Training Location:</h1>
                        <p className='text-lg text-gray-400'>1126 Ozik Grove Avenue, Los Angeles, LA 90002</p>
                    </div>
                </div>
                <div className='lg:w-[50%] flex flex-col gap-5 text-center items-center justify-center'>
                    <p>Enter your email to get curated content from me to keep you feeling good…</p>
                    <form action="" className='flex md:w-[80%] max-md:w-[100%] justify-center items-center pb-1 border-b-[1px] border-gray-600'>
                        <input type="email"
                        placeholder='Enter Email'
                        className='outline-none border-0 w-[80%]  py-2 bg-transparent'
                        required />
                        <button className='text-xl capitalize font-bold '>Sign up</button>
                    </form>
                <div className=" flex w-[100%] mt-5 items-center justify-center gap-5 text-[20px]">
                <FaInstagram className='hover:text-[#d6fb00] transition-all cursor-pointer'/>
                <FaFacebook className='hover:text-[#d6fb00] transition-all cursor-pointer'/>
                <FaYoutube className='hover:text-[#d6fb00] transition-all cursor-pointer'/>
                </div>
                <div className="w-[100%] flex gap-2 text-xs text-gray-500 justify-center items-center text-center">
                    <h1>@2024  </h1>-
                    <h1 className="hover:text-[#d6fb00] transition-all cursor-pointer"> 
                        Privacy Policy 
                    </h1>-
                    <h1 className="hover:text-[#d6fb00] transition-all cursor-pointer">
                        Terms & Conditions
                    </h1>
                </div>
                </div>
            </div>

        </div>
      
    </div>
  )
}
