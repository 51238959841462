import React from 'react'
import { FaAngleDoubleRight } from 'react-icons/fa'

export default function Virtual() {
  return (
    <div className='w-[100vw] text-white flex hero justify-center items-center overflow-hidden'>
      <div className="max-w-[1400px] w-[100%] flex max-md:py-[3rem] px-[3%] pt-[6rem]">
        <div className='w-[100%] md:flex justify-between'>
            <div className='md:w-[50%]'>
                <img src={require('../Assets/virtual.webp')} className='w-[100%]h-[100%] object-cover' alt="" />
            </div>
            <div className='md:w-[40%] max-md:mt-[2rem] flex flex-col max-md:gap-5 md:gap-16'>
                <h1 className="text-5xl font-semibold uppercase">Virtual Personal Training</h1>
                <p className='text-lg font-light'>Pre-recorded workout videos are so yesterday. F7 brings you the best virtual personal training option with live, 1-on-1, training sessions—all from the comfort of your home or any place on the world that you feel comfortable.</p>
                <div className='flex py-2 px-[1rem] bg-yellow-400 w-fit hover:underline gap-2 items-center text-black'>
                <FaAngleDoubleRight className='text-[40px] ' /> <h1 className='uppercase font-semibold'>  contact us </h1>
                </div>
            </div>

        </div>

      </div>
    </div>
  )
}
