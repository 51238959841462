import { motion } from "framer-motion";
import React, { useState } from "react";
import { FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";
import { MdPriceChange, MdTimer } from "react-icons/md";
export default function Teams({ style, img, head, p, card, setCard }) {
  
  return (
    <div
      onMouseLeave={() => setCard(() => false)}
      onMouseEnter={() => setCard(() => true)}
      className={`flex flex-col gap-4 ${style}`}
    >
      <div className="w-[100%] relative h-[30rem] overflow-hidden">
        <motion.img
          animate={{ scale: card ? 1.2 : 1 }}
          transition={{ duration: 0.3, type: "tween" }}
          src={img}
          alt="gym"
          className="w-[100%]"
        />
        <motion.div
          animate={{
            y: card ? 0 : 50,
          }}
          transition={{ duration: 0.3, type: "tween" }}
          className="absolute flex gap-5 items-center bottom-0 w-[100%] text-black bg-[#d6fb00] p-3"
        >
         <div className=" flex w-[100%]  items-center  gap-7 text-[25px]">
                <FaInstagram className=' transition-all cursor-pointer'/>
                <FaFacebook className=' transition-all cursor-pointer'/>
                <FaYoutube className=' transition-all cursor-pointer'/>
                </div>
        </motion.div>
      </div>
      <h1
        className={`text-2xl ${
          card && "text-[#d6fb00] underline"
        } transition-all leading-[1.4rem] font-semibold tracking-wider  `}
      >
        {head}
      </h1>
      <p className="text-sm font-bold uppercase -mt-3 text-gray-600">{p}</p>
     
    </div>
  );
}
