import React, { useState } from 'react'
import Class from '../UI/Class'
import img1 from '../Assets/gregory-gregory-iHCIJ3puFbc-unsplash.jpg'
import img2 from '../Assets/inspired-horizons-digital-marketing-XqxnqyqDU0k-unsplash.jpg'
import img3 from '../Assets/logan-weaver-9D_rUDe7xvA-unsplash-1.jpg'
import img4 from '../Assets/iStock-1213286811.jpg'
export default function Group() {
    const [group,setGroup]=useState(false)
    const [group2,setGroup2]=useState(false)

    const [group3,setGroup3]=useState(false)
    const [group4,setGroup4]=useState(false)

  return (
    <section className='w-[100vw] bg-white hero text-black flex justify-center items-center overflow-hidden '>
      <div className='max-w-[1400px] w-[100%] px-[5%] py-[3rem]  '>
        <div className="w-[100%] flex flex-col gap-10 ">
            <h1 className="text-4xl text-center font-bold uppercase ">
            Group Training
            </h1>
            <div className='grid md:grid-cols-4 max-md:grid-cols-1 gap-7 md:mt-[10rem]'>
                <Class card={group} setCard={setGroup} style={0} img={img1} head={'BATTLEBOX'}
                p={`With plenty of space and fresh air to make the most of, it’s easy to see why so many people love to take their exercise routine outdoors.`}
                />
                <Class card={group2} setCard={setGroup2} style={'md:mt-[-7rem]'}  img={img2} head={'Crossfit'} 
                p={`The magic is in the movement. CrossFit workouts are different every day and can be modified to help each athlete achieve their goals.`}
                />
                <Class card={group3} setCard={setGroup3} style={'md:mt-[-4rem]'}  img={img3} head={'Boxing'} 
                p={`If you love exercising outdoors, and get a thrill from bootcamp-style fitness classes which challenge your whole body, you'll love Boxing.`}/>
                <Class card={group4} setCard={setGroup4} style={0} img={img4} head={'Yoga & Pilates'}
                p={`While both yoga and Pilates are low-impact exercises and can be performed on a mat, they're actually totally different workouts.`}
                />
            </div>
        </div>

      </div>
    </section>
  )
}
