import React, { useState } from 'react'
import Header from '../Header'
import Banner from '../About/Banner'
import About from './About'
import Aminities from './Aminities'
import Digital from './Digital'
import Alabam from '../Alabam'
import Footer from '../About/Footer'
import img from '../Assets/trainer.webp'
export default function IndexCl() {
    const [move,setMove]= useState(false)
  return (
    <div>
      <Header/>
      <Banner text={'Outdoor Classes'} move={move} setMove={setMove}/>
      <About move={move} setMove={setMove}/>
      <Aminities/>
      <Digital h1={'The'} h2={'Trainer'} img={img} p={`The trainers at F7 Fitness are full-blown rock stars who come from
              a variety of backgrounds/disciplines including strength &
              conditioning, figure competitions, bodybuilding, martial arts and
              boxing. We pride ourselves on having only incredibly high-caliber
              trainers available at reasonable and affordable rates.`} title='TALENTED and HIGHLY motivated'/>
      <Alabam/>
      <Footer/>
    </div>
  )
}
