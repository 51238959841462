import React from 'react'
import { IoMdRadioButtonOff, IoMdRadioButtonOn } from 'react-icons/io'

export default function Radio({radio,setRadio,s,p,h1,second,third,setValue}) {
    const handleClick=()=>{
        setRadio(()=>!radio)
        second(()=>false)
        third(()=>false)
        setValue(()=>({
            name:h1,
            price:s
        }))
    }
  return (
   
              <div onClick={handleClick} 
              className={`py-[2rem] px-[2rem]  ${radio ? 'border-[2px] border-black' :'border-[2px] border-gray-500'} flex flex-col gap-3 hover:border-[2px] transition-all hover:border-black`}>
                           
              {radio && <IoMdRadioButtonOn className="text-[3rem]" />}
              {!radio && <IoMdRadioButtonOff className="text-[3rem]" />}
              <h1 className="text-3xl leading-[1.5rem] font-semibold tracking-wide uppercase">
               {h1}
              </h1>
              <span className="text-2xl font-semibold">{s}</span>
              <p className="text-sm">{p}</p>
              </div>)
          
}
