import React, { useState } from 'react'
import img from '../Assets/shape-green.svg'
import { motion } from 'framer-motion'
export default function About() {
 const [move,setMove]=useState(false)
  return (
    <div className='w-[100vw] h-[100%] relative flex justify-center hero items-center '>
        <motion.img animate={{x:move?30:0}} transition={{duration:0.2,type:'tween'}}
         src={img} alt="web agency" className='absolute max-lg:hidden top-[-7.3rem] right-[6rem]' />
        <div className='max-w-[1400px] flex-col w-[100%]  pt-[3rem] flex justify-center items-center'>
            <div 
            onMouseEnter={()=>setMove(()=>true)} onMouseLeave={()=>setMove(()=>false)} 
             className='md:w-[70%] px-[5%] text-2xl font-[500] text-white'>
                <p> 
                
Personal Training at F7 is all about you. You will not only leave each session looking great, but feeling great too. We guarantee you will actually enjoy working out again! Below are just a few of the added benefits and features: </p>
            </div>
            <div className=' md:w-[70%] pb-[3rem]  px-[5%] mt-[5rem]'>
             <div className='grid grid-cols-3 gap-10'>
                <div>
                    <h1 className="text-md text-gray-600 uppercase">
                        01.
                    </h1>
                    <h2 className="text-lg font-semibold text-white">
                    Personal plan
                    </h2>
                </div>
                <div>
                    <h1 className="text-md text-gray-600 uppercase">
                        02.
                    </h1>
                    <h2 className="text-lg font-semibold text-white">
                    Weight loss
                    </h2>
                </div>
                <div>
                    <h1 className="text-md text-gray-600 uppercase">
                        03.
                    </h1>
                    <h2 className="text-lg font-semibold text-white">
                    Toned muscles

                    </h2>
                </div>
                <div>
                    <h1 className="text-md text-gray-600 uppercase">
                        04.
                    </h1>
                    <h2 className="text-lg font-semibold text-white">
                    Improved focus

                    </h2>
                </div>
                <div>
                    <h1 className="text-md text-gray-600 uppercase">
                        05.
                    </h1>
                    <h2 className="text-lg font-semibold text-white">
                    Increased energy

                    </h2>
                </div>
                <div>
                    <h1 className="text-md text-gray-600 uppercase">
                        06.
                    </h1>
                    <h2 className="text-lg font-semibold text-white">
                    New friendships

                    </h2>
                </div>
             </div>
            </div>
        </div>
      
    </div>
  )
}
