import React from 'react'
import Header from '../Header'
import Banner from './Banner'
import About from './About'
import img from '../Assets/trainer.webp'
import Digital from './Digital'
import Virtual from './Virtual'
import Join from '../DigitalMain/Join'
import Alabam from '../Alabam'
import Footer from '../About/Footer'
import Session from './Session'
export default function Personal() {
  return (
    <div>
      <Header/>
      <Banner/>
      <About/>
      <Digital h1={'The'} h2={'Trainer'} img={img} p={`The trainers at F7 Fitness are full-blown rock stars who come from
              a variety of backgrounds/disciplines including strength &
              conditioning, figure competitions, bodybuilding, martial arts and
              boxing. We pride ourselves on having only incredibly high-caliber
              trainers available at reasonable and affordable rates.`} title='TALENTED and HIGHLY motivated'/>
    <Virtual/>
    <Session/>
        <Join/>
        <Alabam/>
        <Footer/>
    
    </div>
  )
}
