import { motion } from 'framer-motion'
import React, { useState } from 'react'
export default function Box() {
    const [card,setCard]=useState(false)
  return (
    <div className='w-[100vw] h-[100%] bg-white flex justify-center hero items-center overflow-hidden'>
      <div className="max-w-[1400px]  px-[5%] py-[2rem] lg:w-[90%] ">
        <a href="/blog/1">
        <div 
        onMouseEnter={()=>setCard(()=>true)}
         onMouseLeave={()=>setCard(()=>false)}
         className='w-[100%]  grid md:grid-cols-2 max-lg:grid-cols-1 md:h-[27rem] overflow-hidden items-center'>
            <div className=" bg-[#d6fb00] h-[100%] items-start justify-center px-[2rem] py-[2rem] flex flex-col gap-3 ">
                <h1 className={`${card && 'underline transition-all'} cursor-pointer max-md:text-[30px] max-md:leading-[30px] md:text-[50px] md:leading-[50px] transition-all capitalize font-bold text-[#252525] hover:text-black`}>
                Jump for Joy with This Leap Year HIIT Workout
                </h1>
                <p className='text-sm  text-left w-fit'>February 7, 2021</p>
                <p className='text-md tracking-wider font-light'>This quick workout takes less than 20 minutes and can be done almost anywhere. You’ve got two goals: Maximize your calorie burn, Train your fitness</p>
            </div>
            <div className='overflow-hidden'>
                <motion.img
                animate={{scale:card?1.3:1}}
                transition={{duration:0.7,type:'tween'}}
                src={require('../Assets/element5-digital-7QYd1VxLRbM-unsplash.webp')} 
                className='w-[100%] h-[100%]'
                alt="" />

            </div>

        </div>
        </a>
      </div>
    </div>
  )
}
