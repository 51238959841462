import React from 'react'
import Header from '../Header'
import Banner from '../About/Banner'
import About from './About'
import Outdoor from './Outdoor'
import Group from './Group'
import Footer from '../About/Footer'
import Team from './Team'
import Digital from './Digital'
import Personal from './Personal'
import Aminities from './Aminities'
export default function IndexC() {
  return (
    <div>
      <Header/>
      <Banner text='classes'/>
      <About/>
      <Outdoor/>
      <Group/>
      <Digital/>
      <Personal/>
      <Aminities/>
      <Team/>
      <Footer/>
          </div>
  )
}
