import { motion } from 'framer-motion'
import React, { useState } from 'react'
import Faqs from '../UI/Faqs'

export default function Faq() {
    const [one, setOne]=useState(false)
    const [two, setTwo]=useState(false)
    const [three, setThree]=useState(false)
    const [four, setFour]=useState(false)
    const [five, setFive]=useState(false)
  return (
    <section className='w-[100vw] hero h-[100%] relative overflow-hidden bg-white flex justify-center items-center'>
      <div className="w-[100%] max-w-[1400px] max-md:px-[5%] md:px-[10%] py-[5rem] md:pb-[15rem] ">
        <div className='w-[100%]  flex flex-col gap-10'>
            <h1 className="text-4xl font-bold uppercase">
                FAQ
            </h1>
            <div className="w-[100%] flex flex-col gap-1">
               <Faqs one={one} setOne={setOne} h={`What classes does F7 Fitness offer?`}
               p={`F7 Fitness has lots of training options available. Personal Training is offered in a one-on-one format lead by a certified personal trainer, providing a very personalized experience. Small group training is similar to personal training, only it’s more fun as there are typically 2-4 people in a session. Group Training include 5+ people and provide accountability and an energy-filled atmosphere that keeps you motivated.`}
               />
                <Faqs one={two} setOne={setTwo} h={`Do F7 Fitness clubs have showers and lockers?`}
               p={`Yes, we do! Making healthy happen should be as easy as possible and the option to take a quick shower after a workout is sometimes the difference between “I can work out” and “I can’t work out.” Our club has showers and bathrooms, and offer lockers.`}
               />
                 <Faqs one={three} setOne={setThree} h={`Does F7 Fitness have basketball courts or swimming pools?`}
               p={`F7 Fitness does not offer basketball courts or swimming pools.`}
               />
                <Faqs one={four} setOne={setFour} h={`How old do you have to be to workout at F7 Fitness?`}
               p={`While there isn’t a set age limit, our Fitness must comply with state laws on age requirements and age restrictions.`}
               />
                  <Faqs one={five} setOne={setFive} h={`Does F7 Fitness have free Wifi?`}
               p={`We offer Wifi. F7 Fitness has separate a Wifi password.`}
               />
            </div>

        </div>
      </div>
      <div className="absolute bottom-[-0.5rem] w-[100%] ">
      <svg xmlns="http://www.w3.org/2000/svg" fill='#191919' viewBox="0 0 1000 100" preserveAspectRatio="none">
<path class="elementor-shape-fill" d="M737.9,94.7L0,0v100h1000V0L737.9,94.7z"></path>
</svg>
      </div>
    </section>
  )
}
