import img1 from '../Assets/element5-digital-7QYd1VxLRbM-unsplash.webp'
export const Data=[
    {
    id:1,
    img:img1,
    header:'Jump for Joy with This Leap Year HIIT Workout',
    type:'Group Exercise',
    typeId:2,
    date:'February 7, 2021',
    Previous:'Muscle Confusion: Myth and Science',
    plink:'/blog/1',
    next:'Muscle Confusion: Myth and Science',
    nlink:'/blog/1',
    intro:[
        {
            id:1,
            p:'This quick workout takes less than 20 minutes and can be done almost anywhere. You’ve got two goals: Maximize your calorie burn, Train your fitness for more intensity, High Intensity Interval Training (HIIT) maximizes your calorie burn by keeping your heart rate high enough during the workout that your body burns calories long after you’re done exercising.'
        },
        {
            id:2,
            p:'This workout pushes your upper body, lower body and core with high intensity so you get the benefits of high-performing cardio with an all-over body benefit that’ll make you jump for joy.'
        }
    ],
    p:[
        {
            id:1,
            head:'1. Air Squats',
            intro:[
                {
                    id:1,
                    p:'Start with your feet shoulder width apart, toes facing forward or slightly turned out.  Make sure that your core is braced, chest up, eyes forward. Start by lowering your butt to knee height (or below if comfortable), your knees should track over toes. Drive through your feet to return to standing position.'
                },
                {
                    id:2,
                    p:`The goal: Perform the move for 30 seconds, and then rest for 15 seconds.`
                }
            ]
        },
        {
            id:2,
            head:'2. Push-ups',
            intro:[
                {
                    id:1,
                    p:'Start with your hands on the floor outsides of your chest, fingers forward, elbows inward to 45 degrees. Your feet should be hip width apart, core tight, and be sure to squeeze your glutes. Keep your eyes down, long, flat back in a straight line from your head to your heels. Begin to lower your chest to floor, and then push through your hands to lift your upper body back to starting position.'
                },
                {
                    id:2,
                    p:`The goal: Perform the move for 30 seconds, and then rest for 15 seconds.`
                }
            ]
        },
        {
            id:2,
            head:'3. Mountain Climbers',
            intro:[
                {
                    id:1,
                    p:'Start with your hands on the floor outsides of your chest, fingers forward, elbows inward to 45 degrees. Your feet should be hip width apart, core tight, and be sure to squeeze your glutes. Keep your eyes down, long, flat back in a straight line from your head to your heels. Begin to lower your chest to floor, and then push through your hands to lift your upper body back to starting position.'
                },
                {
                    id:2,
                    p:`The goal: Perform the move for 30 seconds, and then rest for 15 seconds.`
                }
            ]
        }
    ],
    p1:'Prioritizing frequent, intense workouts while neglecting rest days can not only lead to diminishing performance returns with your fitness, but can also leave you open to injury, fatigue, or burnout, as SELF recently reported.',
    p2:'Limit your HIIT to one or two workouts a week, and make sure you’re balancing them with plenty of easy workouts—as well as at least one straight recovery day per week.',
    tag:'Daily Routine, Exercise, Fitness, Tips and Tricks'


}
]