import React, { useState } from 'react'
import Banner from './Banner'
import Header from '../Header'
import About from './About'
import Mission from './Mission'
import History from './History'
import Amenities from './Amenities'
import Gym from './Gym'
import Faq from './Faq'
import Alabam from '../Alabam'
import Footer from './Footer'

export default function Index() {
    const [move,setMove]=useState(false)
  return (
    <div>
        <Header/>
      <Banner move={move} text={'About'} setMove={setMove}/>
      <About move={move} setMove={setMove}/>
      <Mission/>
      <History/>
      <Amenities/>
      <Gym/>
      <Faq/>
      <Alabam/>
      <Footer/>
    </div>
  )
}
