import React from 'react'
import { FaCheck } from 'react-icons/fa'

export default function Session() {
  return (
    <div className='bg-slate-100 w-[100vw] hero flex justify-center items-center overflow-hidden'>
        <div className='max-w-[1400px] w-[100%] max-md:[5%] lg:px-[15%] py-[5rem]'>
            <div className='w-[100%] md:flex max-md:flex max-md:flex-col max-md:gap-5 justify-between items-center'>
                <div className='md:w-[40%]  flex flex-col gap-3'>
                    <h1 className="text-2xl font-semibold">50 MINUTE SESSIONS</h1>
                    <div className='w-100%] flex gap-10'>

               <div className='flex flex-col gap-3'>
            {['1 Session $70.00',
          '5 Sessions $65.00',
                 ].map((data, index) => ( <div  key={index}  className="w-[100%] flex gap-4 items-center ">

            <FaCheck /> <h1 className="text-md">{data}</h1>
                    </div>
                  ))}
                 </div>
                 <div className='flex flex-col gap-3'>
            {['10 Sessions $50.00',
          '20 Sessions $40.00',
                 ].map((data, index) => ( <div  key={index}  className="w-[100%] flex gap-4 items-center ">

            <FaCheck /> <h1 className="text-md">{data}</h1>
                    </div>
                  ))}
                 </div>
                    </div>
          </div>
                
          <div className='md:w-[40%]  flex flex-col gap-3'>
                    <h1 className="text-2xl font-semibold">25 MINUTE SESSIONS</h1>
                    <div className='w-100%] flex gap-10'>

               <div className='flex flex-col gap-3'>
            {['1 Session $60.00',
          '5 Sessions $55.00',
                 ].map((data, index) => ( <div  key={index}  className="w-[100%] flex gap-4 items-center ">

            <FaCheck /> <h1 className="text-md">{data}</h1>
                    </div>
                  ))}
                 </div>
                 <div className='flex flex-col gap-3'>
            {['10 Sessions $40.00',
          '20 Sessions $30.00',
                 ].map((data, index) => ( <div  key={index}  className="w-[100%] flex gap-4 items-center ">

            <FaCheck /> <h1 className="text-md">{data}</h1>
                    </div>
                  ))}
                 </div>
                    </div>
          </div>
            </div>

        </div>
      
    </div>
  )
}
