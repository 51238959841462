import React, { useEffect, useState } from "react";
import { Data } from "../constant/Blogsd";
import { useParams } from "react-router-dom";
import { FaQuoteLeft, FaTags, FaYoutube } from "react-icons/fa";
import Blogs from "../Blog/Blogs";
export default function Main() {
    const [data,setData]=useState([])
    const {id}=useParams()
    useEffect(()=>{
        setData(Data.filter(d=>d.id===parseInt(id)))
      },[id]
    )
    console.log(id)
  return (
    <main className="w-[100vw] relative bg-white hero flex justify-center items-center overflow-hidden">
      <div className="max-w-[100%] font-light flex flex-col gap-5 md:w-[70%] max-md:px-[5%] pb-[12rem]">
       {data && data.map((data,index)=> 
       <>
       <img
          src={data.img}
          className="w-[100%] h-[25rem] object-cover "
          alt=""
        />
        {data.intro.map((d,i)=>
        <p key={d.id}>
      {d.p}
        </p>)}
        
        <div className="mt-5 w-[100%] flex flex-col gap-7">
            {data.p.map((d,i)=>
                <div key={d.index} className="flex flex-col gap-7">
                <h1 className="text-4xl font-bold">
           
           {d.head}
            </h1>
            <div className="flex flex-col gap-4">
              
                {d.intro.map((d)=><p key={d.id}>{d.p}</p>)}
            </div>
            </div>)}
            <div className="w-[100%] h-[30rem]">
            <iframe className="w-[100%] h-[100%]" src="https://www.youtube.com/embed/S31BOB6Y6E8?si=bjttEivOsFLR96zD" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
            <p>{data.p1}</p>
            <div className="w-[100%] flex justify-center items-center">

            <div className="flex flex-col gap-2 md:w-[70%]">
                <span className="text-[2.5rem] text-yellow-300"><FaQuoteLeft /></span>
                <p className="text-2xl font-[300] tracking-wider">The more intense the workout, the more important the warm-up is. It’s kind of like prepping your nervous system as well. If your body is not ready for that work, the outcome could be injury, or your performance can suffer.</p>
                <div className="w-[95%] flex items-center justify-between">
                    <h1 className="text-md font-semibold tracking-wider uppercase">John Doe</h1>
                    <div className="bg-yellow-300 px-3 w-fit py-3 text-[30px] rounded-full"><FaYoutube /></div>
                </div>
            </div>
            </div>
            <p>
                {data.p2}
            </p>
            <div className="flex md:items-center gap-2">
            <FaTags  className="text-[20px]"/>
            <h1 className="uppercase tracking-wide hover:underline">{data.tag}</h1>
            </div>

        </div>
        <div className="border-[1.3px] w-[100%] border-gray-400 p-[1.5rem] mt-[2rem] flex ">
                <div className="w-[50%] border-r-[1px] flex flex-col border-black">
                    <a href={data.plink}>
                    <h1 className="text-sm leading-[1rem] uppercase  hover:underline font-semibold ">
                    Previous
                    </h1>
                    <span className="text-light text-lg">
                    {data.Previous}
                    </span>
                    </a>
                </div>
                <div className="w-[50%] border-l-[1px] flex flex-col text-right border-black">
                   <a href={data.nlink}>
                    <h1 className="text-sm leading-[1rem] uppercase  hover:underline font-semibold ">
                    NEXT
                    </h1>
                    <span className="text-light text-lg">
                   {data.next}
                    </span>
                    </a>
                </div>
        </div>
        </>)}
      
      </div>
      <div className="absolute bottom-[-0.2rem] w-[100%] ">
      <svg xmlns="http://www.w3.org/2000/svg" fill='#191919' viewBox="0 0 1000 100" preserveAspectRatio="none">
<path class="elementor-shape-fill" d="M737.9,94.7L0,0v100h1000V0L737.9,94.7z"></path>
</svg>
      </div>
    </main>
  );
}
