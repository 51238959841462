import React, { useState } from 'react'
import img from '../Assets/shape-green.svg'
import { motion } from 'framer-motion'
export default function About({move,setMove}) {
 
  return (
    <div className='w-[100vw] h-[100%] relative flex justify-center hero items-center '>
        <motion.img animate={{x:move?30:0}} transition={{duration:0.2,type:'tween'}} src={img} alt="web agency" className='absolute top-[-7.3rem] max-md:hidden right-[6rem]' />
        <div className='max-w-[1400px] flex-col w-[100%]  pt-[3rem] flex justify-center items-center'>
            <div onMouseEnter={()=>setMove(()=>true)} onMouseLeave={()=>setMove(()=>false)}  className='lg:w-[60%] md:w-[80%] max-md:w-[100%] px-[5%] text-2xl font-semibold text-white'>
                <p> F7 is more than a place where high performers come to be their best. F7 has attracted global attention for its method of training and the incorporation of technology in its fitness regimens. It was rated the Top Training Gym in NYC in New York Magazine’s Best Of issue. </p>
            </div>
            <div className=' w-[100%] md:mt-[7rem] max-md:mt-[2rem]'>
                <img src={require('../Assets/risen-wang-20jX9b35r_M-unsplash.jpg')}
                className='w-[100%] h-[100%]'
                alt="" />
                
            </div>
        </div>
      
    </div>
  )
}
