import React from 'react'

export default function Gym() {
  return (
    <div className='w-[100vw] flex h-[100%] overflow-hidden justify-center items-center '>
      <div className="w-[100%] px-[10%] py-[5rem] max-w-[1400px]">
        <div className='flex flex-col gap-10'>
          <h1 className="text-4xl text-center text-white font-bold uppercase">
            the 
            Gym 
           
          </h1>
          <div className=" w-[100%] md:h-[30rem] overflow-hidden max-md:flex max-md:flex-col md:flex max-md:gap-3 md:gap-7 items-center">
            <div className='md:w-[48%] h-[100%]'>
                <img src={require('../Assets/humphrey-muleba-LOA2mTj1vhc-unsplash.webp')}
                className='w-[100%] h-[100%]'
                alt="" />

            </div>
            <div className='flex md:w-[25%] h-[100%] overflow-hidden md:flex-col max-md:gap-3 md:gap-7'>
                <img src={require('../Assets/jan-laugesen-4UbSaPKGRqc-unsplash.webp')} alt="" className=''/>
                <img src={require('../Assets/ambitious-creative-co-rick-barrett-HO4JNVJWS1w-unsplash.webp')} alt="" className='' />

            </div>
            <div className='flex md:w-[25%] h-[100%] overflow-hidden md:flex-col max-md:gap-3 md:gap-7'>
                <img src={require('../Assets/alina-chernysheva-JA2S6sJWleg-unsplash.webp')} alt='gym' />
                <img src={require('../Assets/brett-jordan-NAENofLAWjE-unsplash.webp')} alt="gym" className='' />

            </div>
          </div>
          </div>
      </div>
    </div>
  )
}
