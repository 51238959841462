import React, { useState } from 'react'
import img from '../Assets/shape-green.svg'
import { motion } from 'framer-motion'
export default function About() {
 const [move,setMove]=useState(false)
  return (
    <div className='w-[100vw] h-[100%] relative flex justify-center hero items-center '>
        <motion.img animate={{x:move?30:0}} transition={{duration:0.2,type:'tween'}}
         src={img} alt="web agency" className='absolute max-lg:hidden top-[-7.3rem] right-[6rem]' />
        <div className='max-w-[1400px] flex-col w-[100%]  pt-[3rem] flex justify-center items-center'>
            <div 
            onMouseEnter={()=>setMove(()=>true)} onMouseLeave={()=>setMove(()=>false)} 
             className='md:w-[70%]  px-[5%] text-2xl font-[500] text-white'>
                <p> 
                It’s more than a workout. It’s an experience in modern day fitness technology. Each individual fitness exercise is explained to you by a coach in a high-quality video. Use your home fitness equipment or train with your body weight. </p>
            </div>
            <div className=' md:w-[70%] pb-[3rem]  px-[5%] mt-[5rem]'>
             <div className='grid grid-cols-3 gap-10'>
                <div>
                    <h1 className="text-md text-gray-600 uppercase">
                        01.
                    </h1>
                    <h2 className="text-lg font-semibold text-white">
                    Train from home
                    </h2>
                </div>
                <div>
                    <h1 className="text-md text-gray-600 uppercase">
                        02.
                    </h1>
                    <h2 className="text-lg font-semibold text-white">
                    Individual trainings
                    </h2>
                </div>
                <div>
                    <h1 className="text-md text-gray-600 uppercase">
                        03.
                    </h1>
                    <h2 className="text-lg font-semibold text-white">
                    Personal coach

                    </h2>
                </div>

             </div>
            </div>
        </div>
      
    </div>
  )
}
