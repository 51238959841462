import React from 'react'

export default function Mission() {
  return (
    <div className='bg-white w-[100vw] flex justify-center items-center overflow-hidden'>
      <div className="max-w-[1400px] w-[100%] md:px-[10%] max-md:px-[5%] max-md:py-[3rem] md:py-[7rem] ">
        <div className='md:flex w-[100%] max-md:flex max-md:flex-col justify-between items-center gap-10'>
            <div className='md:w-[40%] flex flex-col md:gap-14  max-md:gap-5 items-start'>
                <h1 className="text-4xl leading-[2rem] font-semibold text-black uppercase hero">Our<br/>
                Mission</h1>
                <p className='text-sm tracking-wider'>
                We are the un-corporation. Our mission is to provide you with all the tools you need to reach your fitness goals in a clean, vibrant, engaging environment you will be proud to call your GYM. We won’t hold you hostage like other fitness clubs, you’ll want to stay because we care!
                </p>

            </div>
            <div className='md:w-[50%] '>
                <img src={require('../Assets/danielle-cerullo-CQfNt66ttZM-unsplash.jpg')} alt="gym" />

            </div>
        </div>
      </div>
    </div>
  )
}
