import { motion } from 'framer-motion'
import React, { useState } from 'react'
import img from '../Assets/shape-green.svg'
export default function About() {
    const [move,setMove]=useState(false)
  return (
    <div className='w-[100vw] h-[100%] relative flex justify-center hero items-center '>
        <motion.img animate={{x:move?30:0}} transition={{duration:0.2,type:'tween'}} src={img}
         alt="web agency" className='absolute top-[-7.3rem] max-md:hidden right-[6rem]' />
        <div className='max-w-[1400px] flex-col w-[100%]  pt-[3rem] flex justify-center items-center'>
            <div onMouseEnter={()=>setMove(()=>true)} onMouseLeave={()=>setMove(()=>false)}  className='md:w-[60%] px-[5%] max-md:text-xl md:text-2xl font-semibold text-white'>
                <p> 
                We set the standard for quality in group fitness training. The variety of programs, value, and level of quality are unmatched in the neighborhood. We may be an old school gym, but we are with the times and provide contemporary classes in various disciplines, including Yoga, Pilates, and Boxing. </p>
            </div>
          
        </div>
      
    </div>
  )
}
