import React from 'react'
import { FaFacebook, FaInstagram } from 'react-icons/fa'

export default function About() {
  return (
    <div className='w-[100vw] px-[3%] h-[5rem]  flex flex-col justify-center items-center overflow-x-hidden'> 
    <div className=' w-[100%] h-[100%] relative  max-w-[1450px] top-0'>

      <div className='absolute text-white text-3xl flex flex-col gap-5 top-0 left-0'>
      <FaInstagram />
      <FaFacebook />
      </div>
     
    </div>
    </div>
  )
}
