import React from "react";
import { MdDoubleArrow } from "react-icons/md";

export default function Experience() {
  return (
    <div className="w-[100vw] flex justify-center items-center overflow-hidden ">
      <div className="max-w-[1400px] w-[100%] h-[100%] px-[3%] md:pt-[10rem] md:pb-[7rem] ">
        <div className="md:flex max-md:flex max-md:flex-col max-md:gap-10 justify-between relative ">
          <div className="md:absolute  md:top-[-13%] md:left-[37%]">
            <h1 className="md:text-[60px] max-md:text-[40px] max-md:leading-[40px] hero text-white font-bold uppercase md:leading-[3.1rem]">
              Use My Experience <br className="max-md:hiddem"/>
              to Achieve the
              <br className="max-md:hiddem"/>
              Physique you
              <br className="max-md:hiddem"/>
              Deserve
            </h1>
          </div>
          <div className="md:w-[43%]">
            <img
              src={require("./Assets/iStock-657773506.webp")}
              className="w-[100%]"
              alt=""
            />
          </div>
          <div className="md:w-[45%] ">
            <div className="w-[100%] md:pt-[10rem] gap-16 h-[100%] flex flex-col items-start justify-center">

            <div className="text-white flex flex-col gap-3">
                <h1 className="text-3xl font-bold">MOTIVATE</h1>
                <p className="md:w-[70%]">Specializing in body recomposition, competition prep for fitness and physique professionals, and providing sport-specific nutritional advice.</p>

            </div>
            <div className="text-white  flex flex-col gap-3">
                <h1 className="text-3xl font-bold">INSPIRE</h1>
                <p className="md:w-[70%]">Whether the priority be hypertrophy gains, fat loss or simply an improvement in physical fitness, each programme is devised on an individual basis.</p>

            </div>
            <div className="text-white flex flex-col gap-3">
                <h1 className="text-3xl font-bold">ACHIEVE</h1>
                <p className="md:w-[70%]">Based on this fitness assessment, I consider a client’s strengths, weaknesses and any personal limitations, preferences or medical considerations that may be applicable.</p>

            </div>
            <div className="flex mt-10 items-center uppercase w-fit justify-start  text-black bg-[#d6fb00] px-4 cursor-pointer py-1">
            <MdDoubleArrow  className='text-[3rem] '/> <h1 className="text-xs hover:underline font-bold">
                    
                     Join Now
                    </h1> 
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
