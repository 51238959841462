import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

// import required modules
import { Navigation } from 'swiper/modules';
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from 'react-icons/ai';
export default function Tsetinomial() {
  return (
    <div className='w-[100vw] h-[50rem] flex justify-center items-center
    overflow-x-hidden relative '>
        <div className="relative h-[100%]  w-[100%] max-w-[1400px]">

    <img src={require('./Assets/iStock-664069200.jpg')} alt="" className='absolute top-0 w-[100%] h-[100%] object-cover' />
        <div className='bg-[#191919]  md:w-[80%] max-md:w-[100%] absolute md:flex max-md:flex max-md:flex-col gap-10 items-center bottom-0 max-md:left-0 md:right-[5%] text-white max-md:px-[1rem] max-md:py-[2rem] md:px-[6rem] md:py-[4rem]'>
        <AiOutlineDoubleLeft className='text-[70px] max-md:hidden custom-prev' />
        <Swiper 
        navigation={{
        nextEl: '.custom-next',
        prevEl: '.custom-prev',
      }} 
      modules={[Navigation]}
      loop={true} className="mySwiper w-[100%] h-[100%] px-[2rem]">
        <SwiperSlide>
            <div className='flex flex-col gap-7'>

            <h1 className='text-2xl  font-semibolg tracking-wide hero'>
            Started the 45 days transformation with Jorge and I'm already seeing the results, just two weeks in! I haven't felt this good in ages!

            </h1>
            <span className='font-bold text-sm uppercase '>Robert Pattinson</span>
            </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className='flex flex-col gap-7'>

            <h1 className='text-2xl  font-semibolg tracking-wide hero'>
            
Jorge is the best trainer I've had! He's got great attention to detail and is very patient with me, which is a rare quality these days. Thanks, Jorge! 

            </h1>
            <span className='font-bold text-sm uppercase '>Joey Potter</span>
            </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className='flex flex-col gap-7'>

            <h1 className='text-2xl  font-semibolg tracking-wide hero'>
            
            Jorge is the best t I've had! He's got great attention to detail and is very patient with me, which is a rare quality these days. Thanks, Jorge! 

            </h1>
            <span className='font-bold text-sm uppercase '>Dawson Leary</span>
            </div>
            </SwiperSlide>
        </Swiper>
        <AiOutlineDoubleRight className='text-[70px] max-md:hidden custom-next font-bold text-white'/>
        <div className='w-[50%] flex gap-10 md:hidden'>
        <AiOutlineDoubleLeft className='text-[70px] custom-prev' />
        <AiOutlineDoubleRight className='text-[70px] custom-next font-bold text-white'/>

        </div>
        </div>
        </div>
      
    </div>
  )
}
