import React from 'react'

export default function Banner({move,setMove,text}) {
  return (
    <div className='mt-[6rem] text-black hero w-[100vw] flex justify-center items-center overflow-hidden bg-white'>
      <div onMouseEnter={()=>setMove && setMove(()=>true)} onMouseLeave={()=>setMove && setMove(()=>false)} className="max-w-[1400px] w-[100%] px-[5%] py-[4rem]">
        <h1 className='md:text-6xl max-md:text-5xl text-[#252525] text-center font-bold uppercase'>
           {text}
        </h1>
      </div>
    </div>
  )
}
