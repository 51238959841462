import { motion } from "framer-motion";
import React, { useState } from "react";
export default function Digital({h1,h2,p,link,img,title}) {
  const [move, setMove] = useState(false);
  return (
    <div className="w-[100vw] relative pb-[5rem] hero z-10 flex justify-center bg-white text-black items-center overflow-hidden">
      <div className="max-w-[1400px] w-[100%] px-[5%] py-[3rem] ">
        <div className="md:flex w-[100%] z-10 relative justify-center items-center">
          <motion.div
            animate={{ x: move ? 100 : 0 }}
            transition={{
              duration: 0.5,
              type: "tween",
            }}
            className="absolute top-0 left-0 max-md:hidden -z-10"
          >
            <svg
              className="-rotate-90"
              xmlns="http://www.w3.org/2000/svg"
              height="330"
              viewBox="0 0 330 330"
              width="330"
            >
              <path
                d="m330 0-165 160.742407-165-160.742407v168.205614l165 161.794386 165-161.794386z"
                fill="#d6fb00"
                fill-rule="evenodd"
              ></path>
            </svg>
          </motion.div>
          <div
            onMouseLeave={() => setMove(() => false)}
            onMouseEnter={() => setMove(() => true)}
            className="md:w-[45%] md:items-center text-left relative top-0 max-md:mb-[2rem] flex flex-col "
          >
            <h1 className="text-4xl text-left uppercase items-start md:ml-[-10rem] leading-[1.8rem] font-semibold ">
             {h1}
            </h1>
            <h1 className="text-[55px] leading-[50px] uppercase font-extrabold ">
             {h2}
            </h1>
          </div>
          <div className="md:w-[55%] hero flex flex-col gap-5">
            <img
              src={img}
              className="w-[100%] h-[100%] "
              alt="gym"
            />
            <h1 className="text-3xl font-semibold ">
             {title}
            </h1>
            <p>
              {p}
            </p>
            <div className="flex items-center gap-1 hover:underline">
              <svg
                className="-rotate-90"
                xmlns="http://www.w3.org/2000/svg"
                height="40"
                viewBox="0 0 330 330"
                width="50"
              >
                <path
                  d="m330 0-165 160.742407-165-160.742407v168.205614l165 161.794386 165-161.794386z"
                  fill="#d6fb00"
                  fill-rule="evenodd"
                ></path>
              </svg>
              <h1 className="text-xs uppercase font-[900]">Learn more</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute bottom-[-0.2rem] w-[100%] ">
      <svg xmlns="http://www.w3.org/2000/svg" fill='#191919' viewBox="0 0 1000 100" preserveAspectRatio="none">
<path class="elementor-shape-fill" d="M737.9,94.7L0,0v100h1000V0L737.9,94.7z"></path>
</svg>
      </div>
    </div>
  );
}
