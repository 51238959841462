import React from 'react'

export default function Map() {
  return (
    <div className='w-[100vw] flex justify-center items-center'>
        <div className='max-w-[1400px] w-[100%]'>
        <iframe className='w-[100%] border-0 max-md:h-[25rem] md:h-[40rem]' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15388.689767552933!2d75.1332999!3d15.3671445!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bb8d799e42ba781%3A0x9fc75fd923994188!2sMuscle%20Factory%20UFC%20Vishweshwar%20Nagar!5e0!3m2!1sen!2sin!4v1725882099050!5m2!1sen!2sin"   allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

        </div>
      
    </div>
  )
}
