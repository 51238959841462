import { motion } from 'framer-motion'
import React, { useState } from 'react'
import { FaPlay } from 'react-icons/fa'
export default function  How() {
    const [move,setMove]=useState(false)
  return (
    <div  onMouseLeave={() => setMove(() => false)}
    onMouseEnter={() => setMove(() => true)} 
    className='w-[100vw] hero z-10 relative bg-white h-[100%] flex justify-center hero items-center overflow-hidden '>
      <motion.div
            animate={{ x: move ? 100 : 0 }}
            transition={{
              duration: 0.5,
              type: "tween",
            }}
            className="absolute max-lg:hidden top-0 left-[2%] -z-10"
          >
            <svg
              className="-rotate-90"
              xmlns="http://www.w3.org/2000/svg"
              height="330"
              viewBox="0 0 330 330"
              width="330"
            >
              <path
                d="m330 0-165 160.742407-165-160.742407v168.205614l165 161.794386 165-161.794386z"
                fill="#d6fb00"
                fill-rule="evenodd"
              ></path>
            </svg>
          </motion.div>
        <div className='max-w-[1400px] w-[100%] h-[100%] gap-20 flex-col flex pr-[5%] pl-[10%] py-[5rem] '>
            <div className='grid lg:grid-cols-3 max-lg:grid-cols-1 max-md:gap-10 md:gap-30 justify-around'>
                <div className='lg:mt-[10rem]'>
                    <span className='uppercase font-[600] text-4xl leadong-[1.7rem]'>
                        How it
                    </span>
                    <h1 className='md:text-[90px] max-md:text-6xl md:leading-[75px] font-bold uppercase'>
                    works
                    </h1>
                </div>
                <div className='lg:w-[80%] lg:mt-[10rem] lg:ml-[15%] flex overflow-hidden flex-col gap-3'>
                    <motion.img
                    whileHover={{scale:1.1,transition:{
                        duration:0.8,
                        type:'tween'
                    }}}
                    src={require('../Assets/iStock-1268991990.jpg')} alt="" />
                    <span className='text-5xl font-bold leading-[2rem]  mt-[2rem]'> 01.</span>
                    <h1 className='text-2xl font-semibold '>
                    Select Plan
                    </h1>
                    <p className='mt-[-0.5rem]'>
                    At-home or on-the-go, practice wherever and whenever you want.</p>
                    <span className='uppercase font-bold '>Join Now</span>
                </div>
                <div className='lg:w-[80%] lg:ml-[15%] flex overflow-hidden flex-col gap-3'>
                    <motion.img
                    whileHover={{scale:1.1,transition:{
                        duration:0.8,
                        type:'tween'
                    }}}
                    src={require('../Assets/alora-griffiths-zEAX0E0KJxs-unsplash.jpg')} alt="" />
                    <span className='text-5xl font-bold leading-[2rem]  mt-[2rem]'> 02.</span>
                    <h1 className='text-2xl font-semibold '>
                    Open link in your email to join us on Zoom
                    </h1>
                    <p className='mt-[-0.5rem]'>
                    One hour before class, you'll receive an email from name@email.com with your Zoom link. Come early to get set up, chat with your teacher, classmates and host.
</p>
                </div>
            </div>
            <div className='w-[100%] lg:flex max-lg:flex max-lg:flex-col-reverse gap-10 '>
                <div className='lg:w-[65%] relative flex justify-center items-center '>
                    <img src={require('../Assets/video-cover.webp')} alt="" className='w-[100%]' />
                    <div className='absolute items-center justify-center flex w-[100%] h-[100%]'>
                    <FaPlay className='text-yellow-300 text-[40px]' />
                    </div>
                </div>

            <div className='lg:w-[27%] lg:mt-[-10rem] lg:ml-[3%]  flex overflow-hidden flex-col gap-3'>
                    <motion.img
                    whileHover={{scale:1.1,transition:{
                        duration:0.8,
                        type:'tween'
                    }}}
                    src={require('../Assets/iStock-1290751594.jpg')} alt="" />
                    <span className='text-5xl font-bold leading-[2rem]  mt-[2rem]'> 03.</span>
                    <h1 className='text-2xl font-semibold '>
                    Enjoy your workout
                    </h1>
                    <p className='mt-[-0.5rem]'>
                    Keep yourself fit and your purse filled. No matter if you´re a youngster or best-ager, beginner or professional. Get motivated and inspired.</p>
                </div>
            </div>
        </div>
      
    </div>
  )
}
