import { motion } from "framer-motion";
import React, { useState } from "react";
export default function Digital() {
  const [move, setMove] = useState(false);
  return (
    <div className="w-[100vw] hero z-10 flex justify-center bg-white text-black items-center overflow-hidden">
      <div className="max-w-[1400px] w-[100%] px-[5%] py-[3rem] ">
        <div className="grid md:grid-cols-2 max-md:grid-cols-1 w-[100%] z-10 relative justify-center items-center">
          <motion.div
            animate={{ x: move ? 100 : 0 }}
            transition={{
              duration: 0.5,
              type: "tween",
            }}
            className="absolute top-0 max-md:hidden left-0 -z-10"
          >
            <svg
              className="-rotate-90"
              xmlns="http://www.w3.org/2000/svg"
              height="330"
              viewBox="0 0 330 330"
              width="330"
            >
              <path
                d="m330 0-165 160.742407-165-160.742407v168.205614l165 161.794386 165-161.794386z"
                fill="#d6fb00"
                fill-rule="evenodd"
              ></path>
            </svg>
          </motion.div>
          <div
            onMouseLeave={() => setMove(() => false)}
            onMouseEnter={() => setMove(() => true)}
            className=" md:items-center text-left relative top-0  flex flex-col "
          >
            <h1 className="text-4xl text-left items-start md:ml-[-8.6rem] leading-[1.8rem] font-semibold ">
              DIGITAL
            </h1>
            <h1 className="text-[55px] leading-[50px] font-extrabold ">
              COACHING
            </h1>
          </div>
          <div className=" hero flex max-md:mt-[3rem] flex-col gap-5">
            <img
              src={require("../Assets/digital.jpg")}
              className="w-[100%] h-[100%] "
              alt="gym"
            />
            <h1 className="text-3xl font-semibold ">
              WORKOUTS WHEREVER
            </h1>
            <p>
              At-home or on-the-go, practice wherever and whenever you want. Our
              virtual, holistic lifestyle coaching and training sessions are
              designed to activate, stretch, move and strengthen your body. Our
              Trainers connect with you for coaching sessions customized to
              deliver the inspiration and motivation you need to reach your
              goals.
            </p>
            <div className="flex items-center gap-1 hover:underline">
              <svg
                className="-rotate-90"
                xmlns="http://www.w3.org/2000/svg"
                height="40"
                viewBox="0 0 330 330"
                width="50"
              >
                <path
                  d="m330 0-165 160.742407-165-160.742407v168.205614l165 161.794386 165-161.794386z"
                  fill="#d6fb00"
                  fill-rule="evenodd"
                ></path>
              </svg>
              <h1 className="text-xs uppercase font-[900]">Learn more</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
