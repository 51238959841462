import React, { useEffect } from 'react'
import { useState } from 'react'
import { FaFacebook, FaInstagram, FaYoutube } from 'react-icons/fa'
import { useParams } from 'react-router-dom'
import { Data } from '../constant/Blogsd'

export default function Head() {
  const [data,setData]=useState([])
 const {id}=useParams()
 console.log(id)
  useEffect(()=>{
      setData(()=>Data.filter(d=>d.id===parseInt(1)))
    },[id]
  )
  return (
    <header className='w-[100vw] mt-[5rem] bg-white hero flex justify-center items-center overflow-hidden'>
     {Data && data.map((d,i)=> <div className='max-w-[100%] flex flex-col gap-5 max-lg:px-[5%] lg:w-[70%] pb-[1rem] pt-[4rem] '>
        <h1 className="md:text-[60px] max-md:text-[40px] max-md:leading-[40px] md:leading-[55px] text-center  font-bold capitalize"> 
            {d.header}
            </h1>
            <div className='md:flex max-md:flex max-md:flex-col max-md:gap-5 justify-center w-[100%] items-center  md:gap-28'>
                <span className='hover:underline transition-all uppercase'>{d.date}</span>
                <span className='hover:underline transition-all uppercase'>{d.type}</span>
            </div>
            <div className=" flex mt-[1rem]  items-center justify-center gap-10 text-[30px]">
                <FaInstagram className='hover:text-[#d6fb00] transition-all cursor-pointer'/>
                <FaFacebook className='hover:text-[#d6fb00] transition-all cursor-pointer'/>
                <FaYoutube className='hover:text-[#d6fb00] transition-all cursor-pointer'/>
        </div>
      </div>)}
    </header>
  )
}
