import React from "react";
import { FaCheck } from "react-icons/fa";

export default function Amenities() {
  return (
    <div className="w-[100vw] h-[100%] bg-white text-black flex justify-center items-center">
      <div className="max-w-[1400px] md:px-[10%] max-md:px-[5%] py-[2rem] pb-[5rem] w-[100%] ">
        <h1 className="text-4xl font-semibold hero tracking-wider uppercase">
          Amenities
        </h1>
        <div className="grid md:grid-cols-3 max-md:grid-cols-1 md:gap-5 lg:gap-10 mt-10">
          
            <div className="flex flex-col items-center gap-3 text-sm">
            {['Fully Supervised Nursery',
            'Fitness Assessment Program',
            'Tanning and Massage',
            'Cardio and Strength Equipment'
          ].map((data, index) => ( <div  key={index}  className="w-[100%] flex gap-4 items-center ">

              <FaCheck /> <h1 className="text-sm">{data}</h1>
                </div>
          ))}
            </div>
            <div className="flex flex-col items-center gap-3 text-sm">
            {['Members Lounge',
            'Nutrition and Supplement Sales',
            'Storage Area',
            'Showers, Changing'
          ].map((data, index) => ( <div  key={index}  className="w-[100%] flex gap-4 items-center ">

              <FaCheck /> <h1 className="text-sm">{data}</h1>
                </div>
          ))}
            </div>
            <div className="flex flex-col items-center gap-3 text-sm">
            {['Showers, Changing',
            'Snake Bar',
            'Sound System',
            'Child Care'
          ].map((data, index) => ( <div  key={index}  className="w-[100%] flex gap-4 items-center ">

              <FaCheck /> <h1 className="text-sm">{data}</h1>
                </div>
          ))}
            </div>
        </div>
      </div>
    </div>
  );
}
