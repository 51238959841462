import { FaAngleDoubleRight } from 'react-icons/fa';
import React, { useRef } from 'react';

export default function Send({ value,next,setNext }) {
  const name = useRef();
  const email = useRef();
  const dateOfBirth = useRef();
  const street = useRef();
  const city = useRef();
  const state = useRef();
  const zipCode = useRef();
  const phone = useRef();
  const gender = useRef();
  const formRef = useRef(); 
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent page reload

    // Collecting form data
    const data = {
      title: value.name,
      price: value.price,
      name: name.current.value,
      email: email.current.value,
      dateOfBirth: dateOfBirth.current.value,
      street: street.current.value,
      city: city.current.value,
      state: state.current.value,
      zipCode: zipCode.current.value,
      phone: phone.current.value,
      gender: gender.current.value
    };
    
    console.log(data);
    setNext(()=>false)
    alert('thank you for your time')
    formRef.current.reset();
  };


    // Handle the form submission logic (e.g., send to a server)
 

  return (
    <div className="w-[100%] flex">
      <form ref={formRef} onSubmit={handleSubmit} className="w-[100%] flex flex-col gap-5">
        <input
          ref={name}
          type="text"
          placeholder="Name"
          name="name"
          className="hover:outline-none outline-none border-[2px] hover:border-transparent transition-all duration-500 border-black w-[100%] h-[3rem] px-[1rem] py-1 bg-white"
          required
        />
        <div className="w-[100%] flex items-center max-md:gap-2 md:gap-5">
          <input
            ref={email}
            type="email"
            placeholder="Email"
            name="email"
            className="hover:outline-none outline-none border-[2px] hover:border-transparent transition-all duration-500 border-black w-[50%] h-[3rem] px-[1rem] py-1 bg-white"
            required
          />
          <input
            ref={dateOfBirth}
            type="date"
            name="dateOfBirth"
            className="hover:outline-none outline-none border-[2px] hover:border-transparent transition-all duration-500 border-black w-[50%] h-[3rem] px-[1rem] py-1 bg-white"
            required
          />
        </div>

        <div className="w-[100%] grid grid-cols-3 items-center max-md:gap-3 md:gap-7">
          <input
            type="text"
            ref={street}
            placeholder="Street address"
            name="street"
            className="hover:outline-none outline-none border-[2px] hover:border-transparent transition-all duration-500 border-black h-[3rem] px-[1rem] py-1 bg-white"
            required
          />
          <input
            ref={city}
            type="text"
            placeholder="City"
            name="city"
            className="hover:outline-none outline-none border-[2px] hover:border-transparent transition-all duration-500 border-black h-[3rem] px-[1rem] py-1 bg-white"
            required
          />
          <input
            type="text"
            ref={state}
            placeholder="State"
            name="state"
            className="hover:outline-none outline-none border-[2px] hover:border-transparent transition-all duration-500 border-black h-[3rem] px-[1rem] py-1 bg-white"
            required
          />
        </div>

        <div className="w-[100%] grid grid-cols-3 items-center max-md:gap-3 md:gap-7">
          <input
            type="text"
            ref={zipCode}
            placeholder="Zip Code"
            name="zipCode"
            className="hover:outline-none outline-none border-[2px] hover:border-transparent transition-all duration-500 border-black h-[3rem] px-[1rem] py-1 bg-white"
            required
          />
          <input
            type="tel"
            ref={phone}
            placeholder="Phone"
            name="phone"
            className="hover:outline-none outline-none border-[2px] hover:border-transparent transition-all duration-500 border-black h-[3rem] px-[1rem] py-1 bg-white"
            required
          />
          <select
            ref={gender}
            className="hover:outline-none outline-none border-[2px] hover:border-transparent transition-all duration-500 border-black h-[3rem] px-[1rem] py-1 bg-white"
            required
          >
            <option value="">Gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
        </div>

        <label className="w-[100%] flex gap-3">
          <input type="checkbox" required />
          <h1 className="w-[100%]">I agree to the terms and conditions.</h1>
        </label>

        <div className="flex w-[100%] items-center justify-between">
          <h1 onClick={()=>setNext(()=>false)} className="text-xl cursor-pointer uppercase font-bold tracking-wide underline text-gray-600">
            Back
          </h1>
          <button
            type="submit" // Ensure this button submits the form
            className="flex items-center hover:underline gap-2 cursor-pointer text-xl font-bold rounded-full transition-all duration-300 ease-in-out"
          >
            <FaAngleDoubleRight className="text-5xl" /> <h1>Send</h1>
          </button>
        </div>
      </form>
    </div>
  );
}
