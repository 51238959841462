import React from 'react'
import img from '../Assets/shape-green.svg'
import { motion } from 'framer-motion'

import Groups from './Groups.'
export default function About({move,setMove}) {
 
  return (
    <div className='w-[100vw] h-[100%] relative flex justify-center hero items-center '>
        <motion.img animate={{x:move?30:0}} transition={{duration:0.2,type:'tween'}} 
        src={img} alt="web agency" className='absolute max-md:hidden top-[-7.3rem] right-[6rem]' />
        <div className='max-w-[1400px] flex-col w-[100%]  pt-[3rem] flex justify-center items-center'>
            <div onMouseEnter={()=>setMove(()=>true)} onMouseLeave={()=>setMove(()=>false)} 
             className='xl:w-[60%] md:w-[80%]  px-[5%] text-2xl font-[500] text-white'>
                <p>
              
Head to a group fitness class on the days you’re not motivated to work out. If your problem is getting to the gym in the first place, a group class can be all the motivation you need: it would be rude to sign up and bail, leaving an open spot that could otherwise have been filled by someone else.
 </p>
            </div>
            <div className=' w-[100%] mt-[0rem]'>
                <Groups/>
            </div>
        </div>
      
    </div>
  )
}
