import { motion } from "framer-motion";
import React, { useState } from "react";
import { MdPriceChange, MdTimer } from "react-icons/md";
export default function Class({ style, img, head, p, card, setCard }) {
  
  return (
    <div
      onMouseLeave={() => setCard(() => false)}
      onMouseEnter={() => setCard(() => true)}
      className={`flex flex-col gap-4 ${style}`}
    >
      <div className="w-[100%] relative h-[30rem] overflow-hidden">
        <motion.img
          animate={{ scale: card ? 1.2 : 1 }}
          transition={{ duration: 0.3, type: "tween" }}
          src={img}
          alt="gym"
          className="w-[100%]"
        />
        <motion.div
          animate={{
            y: card ? 0 : 50,
          }}
          transition={{ duration: 0.3, type: "tween" }}
          className="absolute flex gap-5 items-center bottom-0 w-[100%] text-black bg-[#d6fb00] p-3"
        >
          <div className="flex items-center gap-3">
            <MdTimer />
            <h1 className="textsm font-semibold ">30 Min</h1>
          </div>
          <div className="flex items-center gap-3">
            <MdPriceChange />
            <h1 className="textsm font-semibold ">20$/MO</h1>
          </div>
        </motion.div>
      </div>
      <h1
        className={`text-3xl ${
          card && "text-[#d6fb00] underline"
        } transition-all leading-[1.75rem] font-bold uppercase `}
      >
        {head}
      </h1>
      <p className="text-sm">{p}</p>
      <span className="text-sm uppercase font-bold hover:text-[#d6fb00]">
        View details
      </span>
    </div>
  );
}
