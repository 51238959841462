import React, { useState } from 'react'
import Class from '../UI/Class'
import img1 from '../Assets/iStock-996182226.jpg'
import img2 from '../Assets/karsten-winegeart-ZiXqi1iSZyI-unsplash-1.jpg'
import img3 from '../Assets/iStock-857901800.jpg'
import img4 from '../Assets/iStock-1213287093.jpg'
export default function Outdoor() {
    const [card,setCard]=useState(false)
    const [card2,setCard2]=useState(false)

    const [card3,setCard3]=useState(false)
    const [card4,setCard4]=useState(false)

  return (
    <section className='w-[100vw] hero text-white flex justify-center items-center overflow-hidden '>
      <div className='max-w-[1400px] w-[100%] px-[5%] py-[6rem]  '>
        <div className="w-[100%] flex flex-col gap-10 ">
            <h1 className="text-4xl text-center font-bold uppercase ">
                outdoor
            </h1>
            <div className='grid lg:grid-cols-4 max-lg:grid-cols-1 gap-7 md:mt-[10rem]'>
                <Class card={card} setCard={setCard} style={0} img={img1} head={'High-intensity training (HIT)'}
                p={`If you love exercising outdoors, and get a thrill from bootcamp-style fitness classes which challenge your whole body, you'll love HIT.`}
                />
                <Class card={card2}  setCard={setCard2} style={`md:mt-[-7rem]`} img={img2} head={'Crossfit'} 
                p={`The magic is in the movement. CrossFit workouts are different every day and can be modified to help each athlete achieve their goals.`}
                />
                <Class card={card3} setCard={setCard3} style={'md:mt-[-4rem]'} img={img3} head={'Boxing'} 
                p={`If you love exercising outdoors, and get a thrill from bootcamp-style fitness classes which challenge your whole body, you'll love Boxing.`}/>
                <Class card={card4} setCard={setCard4} style={0} img={img4} head={'Yoga & Pilates'}
                p={`While both yoga and Pilates are low-impact exercises and can be performed on a mat, they're actually totally different workouts.`}
                />
            </div>
        </div>

      </div>
    </section>
  )
}
