import React from 'react'
import Header from '../Header'
import Banner from '../About/Banner'
import Box from './Box'
import Blogs from './Blogs'
import Alabam from '../Alabam'
import Footer from '../About/Footer'

export default function IndexB() {
  return (
    <div>
      <Header/>
      <Banner text={'Blogs'}/>
      <Box/>
      <Blogs/>
      <Alabam/>
      <Footer/>
    </div>
  )
}
