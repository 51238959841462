import React from 'react'
import { FaCheck } from 'react-icons/fa'

export default function Aminities() {
  return (
    <div className="w-[100vw] h-[100%] bg-white text-black flex justify-center items-center">
    <div className="max-w-[1400px] px-[5%] md:py-[7rem] max-md:py-[3rem] w-[100%] ">
      <h1 className="text-4xl leading-[2rem] font-semibold hero tracking-wider uppercase">
      Outdoor Classes <br className='max-md:hidden'/>
      Benefits
      </h1>
      <div className="grid md:grid-cols-3 max-md:grid-cols-1 gap-10 mt-10">
        
          <div className="flex flex-col items-center gap-3 text-md">
          {['Make Exercise Fun',
          'Improve Health',
        ].map((data, index) => ( <div  key={index}  className="w-[100%] flex gap-4 items-center ">

            <FaCheck /> <h1 className="text-md">{data}</h1>
              </div>
        ))}
          </div>
          <div className="flex flex-col items-center gap-3 text-md">
          {['It’s a natural antidepressant',
          'It provides mental relief'
        ].map((data, index) => ( <div  key={index}  className="w-[100%] flex gap-4 items-center ">

            <FaCheck /> <h1 className="text-md">{data}</h1>
              </div>
        ))}
          </div>
          <div className="flex flex-col items-center gap-3 text-md">
          {['Variation in your regular workout',
          'Better mood'
        ].map((data, index) => ( <div  key={index}  className="w-[100%] flex gap-4 items-center ">

            <FaCheck /> <h1 className="text-md">{data}</h1>
              </div>
        ))}
          </div>
      </div>
    </div>
  </div>
  )
}
