import React from "react";

export default function About1() {
  return (
    <div className="w-[100vw]  flex justify-center h-[100%] items-center overflow-hidden">
      <div className=" max-w-[1400px] flex justify-center max-md:py-[3rem] max-md:px-[4%] items-center w-[100%]">
        <div className=" md:w-[70%] max-md:w-[100%] md:mt-[-5rem] md:pb-[3rem] relative md:flex max-md:flex max-md:flex-col justify-center items-center">
          <div className="md:w-fit max-md:w-[100%] relative -z-10 ">
            <img
              src={require("./Assets/iStock-655137780.webp")}
              alt=""
              className="md:w-[20rem] max-md:w-[100%]  md:left-0 relative  "
            />
            <img
              src={require("./Assets/Play-1.png")}
              className="absolute w-[12rem] max-md:hidden  top-[-25%] right-[-32%] "
              alt=""
            />
          </div>
          <div className="md:pt-[35rem] flex flex-col md:gap-16 max-md:gap-5 max-md:mt-[3rem]  md:ml-[-20%]  text-white uppercase md:w-[80%]">

          <p className="md:text-3xl max-md:text-lg font-bold  ">
            My name is Jorge Franklin. I am a certified strength coach,
            nutritionist, and personal trainer. Build for you the safest and
            most effective exercise and nutrition program possible, and make
            sure you have the absolute best experience every time we train
            together.
          </p>
          <h1 className="text-sm font-bold hover:bg-[#d6fb00] px-2 pr-2 w-fit hover:text-black transition-all">
          Read More
          </h1>
          </div>
        </div>
      </div>
    </div>
  );
}
