import React from 'react'
import Header from '../Header'
import Banner from '../About/Banner'
import Head from './Head'
import Main from './Main'
import Footer from '../About/Footer'
import Alabam from '../Alabam'

export default function IndexD() {
  return (
    <div>
      <Header/>
     <Head/>
     <Main/>
     <Alabam/>
     <Footer/>
    </div>
  )
}
