import { motion } from "framer-motion";
import React from "react";
import { FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";
import { IoCloseSharp } from "react-icons/io5";
import { MdDoubleArrow } from "react-icons/md";
export default function Menu({toggle,setToggle}) {
  return (
    <motion.nav
    animate={{
      zIndex: toggle ? 100 : -100,
      backgroundColor: toggle ? '#191919' : 'transparent',
      opacity: toggle ?1:0,
      height: toggle ? '100vh' : '0',
      justifyContent: toggle ? 'center' : 'flex-start',
      boxShadow: toggle ? '0px 5px 15px rgba(0, 0, 0, 0.2)' : 'none',
      transform: toggle ? 'translateX(0%)' : 'translateX(100%)', // Changed the direction of translateX for clarity
      display: toggle ? 'flex' : 'none',
      position: 'fixed' // Kept position always fixed
  }}
  transition={{
      ease: 'anticipate',
  }}
    
      className="w-[100vw] h-[100vh] flex bg-[#191919] justify-center hero items-center  fixed top-0 "
     
    >
      <div className="w-[100%] flex-col h-[100%] justify-between items-center relative max-w-[1400px] flex px-[5%] py-[6rem] ">
        <div onClick={()=>setToggle(()=>false)} className="absolute top-[3rem]  left-[4%] hover:text-[#d6fb00] text-white text-[30px]">
        <IoCloseSharp />

        </div>
        <motion.div
         className="lg:flex max-lg:flex max-lg:flex-col items-center w-[100%] h-fit max-md:gap-10  justify-center md:gap-24">
          <h1
            className={`text-3xl tracking-wide font-bold uppercase pb-2 px-2   
               border-[#d6fb00] border-b-[2px]`}
          >
            <a href='/'>
            Home
            </a>
          </h1>
          <h1
            className={`text-3xl tracking-wide font-bold uppercase pb-2 px-2   
               hover:border-[#d6fb00] border-b-[2px] border-transparent`}
          >
            <a href="/classes">
            CLasses
            </a>
          </h1>
          <h1
            className={`text-3xl tracking-wide font-bold uppercase pb-2 px-2   
               hover:border-[#d6fb00] border-b-[2px] border-transparent`}
          >
            <a href="/about">
            About me
            </a>
          </h1>
          <h1
            className={`text-3xl tracking-wide font-bold uppercase pb-2 px-2   
               hover:border-[#d6fb00] border-b-[2px] border-transparent`}
          >
            <a href="blogs">
            Blogs
            </a>
              
          </h1>
          <h1
            className={`text-3xl tracking-wide font-bold uppercase pb-2 px-2   
               hover:border-[#d6fb00] border-b-[2px] border-transparent`}
          >
          <a href="/contact">
          Contact
          </a>
          </h1>
        </motion.div>

        <div className="flex flex-col max-md:mt-[3rem] gap-10 w-[100%] justify-center items-center"> 
            <h1 className=" md:text-[60px] max-md:text-[40px] max-md:leading-[40px] uppercase font-bold text-center md:leading-[55px] ">
            Are you ready to <br className="max-md:hidden" />  start?
            </h1>
              
             <div className="bg-[#d6fb00] hover:underline flex items-center gap-2  px-6 py-2 text-sm tracking-wide font-bold uppercase text-black">
             <MdDoubleArrow className="text-[40px] inline-flex" /><h1>
                <a href="/contact">

                  Get free consultation
                </a>
                </h1>

             </div>
             <div className=" flex w-[100%]  items-center justify-center gap-5 text-[30px]">
                <FaInstagram className='hover:text-[#d6fb00] transition-all cursor-pointer'/>
                <FaFacebook className='hover:text-[#d6fb00] transition-all cursor-pointer'/>
                <FaYoutube className='hover:text-[#d6fb00] transition-all cursor-pointer'/>
                </div>
        </div>
      </div>
    </motion.nav>
  );
}
