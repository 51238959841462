import { motion } from 'framer-motion'
import React from 'react'
export default function Train({y, img, setCard,card,y1, label}) {
  return (
    <motion.div
    onMouseOver={()=>setCard(()=>true)}
    onMouseLeave={()=>setCard(()=>false)}
    style={{ y: y1 }}
    transition={{ duration: 1, type: "spring" }}
    className="lg:w-[33%] max-lg:w-[100%] gap-10 flex flex-col overflow-hidden"
  >
    <div className="h-[30rem] overflow-hidden">
      <motion.img
      animate={{scale:card?1.2:1}}
      transition={{duration:0.3,type:'tween'}}
        src={img}
        className="w-[100%] h-[100%] object-cover"
        alt=""
      />
    </div>
    <h1 className={ `${card && 'text-[#d6fb00]'} text-3xl leading-[1.7rem] uppercase  font-bold `}>
      {label}
    </h1>
  </motion.div>
  )
}
