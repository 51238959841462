import React from 'react'
import { FaInstagram } from 'react-icons/fa'
import img1 from './Assets/149783819_1322285844823851_787730271173955498_n.jpg'
import img2 from './Assets/album.jpg'
import img3 from './Assets/album2.jpg'
import img4 from './Assets/album4.jpg'
import img5 from './Assets/album5.jpg'
import img6 from './Assets/album6.jpg'
import { motion } from 'framer-motion'
const images = [img1, img2, img3, img4, img5, img6]
export default function Alabam() {
  return (
    <div className='w-[100vw] h-[100%] flex justify-center items-center overflow-hidden'>
      <div className="max-w-[1550px] w-[100%] pt-[5rem]">
        <div className="flex md:h-[15rem] max-md:h-[10rem] ">
            <div className='px-3 max-md:hidden flex flex-col bg-[#d6fb00] gap-2   items-center text-black w-fit h-[100%] justify-center'>
           <h1 className='items-center  text-sm justify-center  font-bold flex flex-col '>{'@trainwithjorge'.split('').reverse().map((d,i)=>(
            <span className='-rotate-90 -mt-[0.6rem] inline-flex' key={i}>{d}</span>
           ))}</h1>
            <FaInstagram  className='text-2xl'/>
            </div>
            <div className='w-[100%] h-[100%] grid grid-cols-6 overflow-hidden'>
                {
            images.map((img, i) => (
                <div className='overflow-hidden ' key={i}>

                    <motion.img
                    initial={{scale:1}}
                    whileHover={{scale:1.1,transition:{
                      duration:0.5
                    }}}
                    src={img} alt="" key={i} className="w-[100%] h-[100%] object-cover" />
                </div>
            ))}

            </div>
        </div>
      </div>
    </div>
  )
}
