import React from 'react'

export default function Digital() {
  return (
    <div className='w-[100vw] flex h-[100%] justify-center items-center  overflow-hidden '>
        <div className="max-w-[1400px] px-[5%] py-[7rem] w-[100%] ">
            <div className='w-[100%] flex justify-between items-center'>
                <div className='lg:w-[15%]'>

                </div>
                <div className='lg:w-[85%]'>
                    <div className='w-[100%] lg:flex max-lg:flex max-lg:flex-col justify-between items-center'>
                        <div className='lg:w-[35%] items-center justify-center'>
                            <div className='hero text-white flex flex-col gap-10'>
                                    <h1 className="md:text-[60px] max-md:text-[40px] max-md:leading-[35%] uppercase md:leading-[55px] font-bold">
                                    Digital coachin
                                    </h1>
                                    <div className="flex flex-col gap-5 text-md">
                                        <p>Online coaching can be very effective. Stay motivated and achieve your goals with video calls and customized workout & nutrition programs.</p>
                                        <p>Online personal training has become a great opportunity recently. Not only does it keep you safe at home, but also you have the opportunity to work with some great personal trainers. I would love the opportunity to help you achieve your fitness goals!</p>
                                    </div>
                                    <div className="uppercase px-2 py-1 cursor-pointer w-fit hover:text-black transition-all hover:bg-[#d6fb00] text-sm font-bold">
                                        join now
                                    </div>
                            </div>
                        </div>
                        <div className='lg:w-[55%]'>
                            <img src={require('./Assets/iStock-1264469610-Copy.webp')} className='w-[100%]' alt="" />

                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
  )
}
