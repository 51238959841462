import React, { useState } from 'react'
import Artical from './UI/Artical'
import img1 from './Assets/john-fornander-lgIe-hgASXE-unsplash.webp'
import img2 from './Assets/vitalii-pavlyshynets-kcRFW-Hje8Y-unsplash.webp'
import img3 from './Assets/iStock-845877576-3.webp'
export default function Articals() {
    const [card1,setCard1]=useState(false)
    const [card2,setCard2]=useState(false)
    const [card3,setCard3]=useState(false)

  return (
    <div className='w-[100vw] flex hero overflow-hidden justify-center text-white items-center '>
        <div className="w-[100%] px-[3%] py-[5rem] max-w-[1400px]  ">
            <div className='flex flex-col gap-10'>
                <h1 className="md:text-[60px] max-md:text-[40px] max-md:leading-[35px] md:leading-[55px]  font-bold uppercase">
                latest <br/>
                articles
                </h1>
                <div className='w-[100%] grid md:grid-cols-3 max-md:grid-cols-1 gap-5'>
                    <Artical card={card1} setCard={setCard1} img={img1} name={'Can you build muscle with smaller weights?'} date={'February 15, 2021'}/>
                    <Artical card={card2} setCard={setCard2} img={img2} name={'Why you need a personal dietary approach'} date={'February 15, 2021'}/>
                    <Artical card={card3} setCard={setCard3} img={img3} name={'48 Days body transformation with Jorge'} date={'February 15, 2021'}/>

                </div>

            </div>
        </div>
      
    </div>
  )
}
