import {
  motion,
  useScroll,
  useTransform,

} from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import Train from "./UI/Train";

export default function Training() {
  const { scrollY } = useScroll();
  const [startPosition, setStartPosition] = useState(0);
  const sectionRef = useRef(null); // Reference for the section
  const [card1, setCard1] = useState(false);
  const [card2, setCard2] = useState(false);
  const [card3, setCard3] = useState(false);
  // Get the position of the section
  useEffect(() => {
    if (sectionRef.current) {
      const sectionTop =
        sectionRef.current.getBoundingClientRect().top + window.scrollY;
      setStartPosition(sectionTop); // Set starting position for animation
    }
  }, [startPosition]);

  const y1 = useTransform(
    scrollY,
    [startPosition - 400, startPosition + 300],
    [0, -100]
  );

  // Create a transform effect for the photo, start only after reaching the section
  const y = useTransform(
    scrollY,
    [startPosition, startPosition + 30, startPosition + 60, startPosition + 80],
    [1, 0.9, 0.7, 0.3]
  );
  return (
    <section className="w-[100vw] flex justify-center items-center text-white overflow-hidden">
      <div
        ref={sectionRef}
        className="flex px-[5%] md:gap-32 max-md:gap-20 flex-col max-md:py-[3rem] md:py-[5rem] max-w-[1400px] w-[100%]   justify-center items-center"
      >
        <motion.h1
          style={{ opacity: y }}
          className="md:text-[60px] max-md:text-[40px] max-md:leading-[35px] font-[700]  text-center uppercase md:leading-[55px]"
        >
          start training <br />
          with me
        </motion.h1>
        <div
         
          className="lg:w-[80%] max-lg:w-[100%] flex  justify-center items-center "
        >
          <div className="lg:flex max-lg:flex max-lg:flex-col w-[100%] gap-5">
            <Train
            card={card1}
            setCard={setCard1}
              y1={y1}
              label={
                <>
                  Outdoor <br /> Classes
                </>
              }
              img={
                "https://f7.vamtam.com/wp-content/uploads/2021/02/iStock-1225040112.jpg"
              }
            />
            <Train
              card={card2}
              setCard={setCard2}
              label={
                <>
                  Personal <br />
                  Training
                </>
              }
              img={
                "https://f7.vamtam.com/wp-content/uploads/2021/02/john-arano-h4i9G-de7Po-unsplash-copy.jpg"
              }
            />

            <Train
              y1={y1}
              card={card3}
              setCard={setCard3}
              label={
                <>
                  Group
                  <br />
                  Training
                </>
              }
              img={
                "https://f7.vamtam.com/wp-content/uploads/2021/02/iStock-662050820-e1614538218330.jpg"
              }
            />
          </div>
        </div>
      </div>
    </section>
  );
}
