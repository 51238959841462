import React, { useState } from 'react'
import Banner from '../About/Banner'

import Header from '../Header'
import About from './About'
import Footer from '../About/Footer'
import Contact from './Contact'
import Map from './Map'
export default function Indexs() {
    const [move,setMove]=useState(false)
  return (
    <div>
    <Header/>
  <Banner move={move} text='Contact' setMove={setMove}/>
  <About move={move} setMove={setMove}/>
  <Contact/>
  <Map/>
  <Footer/>
    </div>
  )
}
