import { motion } from 'framer-motion'
import React, { useState } from 'react'
import Blog from '../UI/Blog'
import img1 from '../Assets/john-fornander-lgIe-hgASXE-unsplash.webp'
import img2 from '../Assets/vitalii-pavlyshynets-kcRFW-Hje8Y-unsplash.webp'
import img3 from '../Assets/iStock-845877576-3.webp'
import img4 from '../Assets/blog4.webp'
import img5 from '../Assets/blog5.webp'
import img6 from '../Assets/blog6.webp'
export default function Blogs() {
    const [card,setCard]=useState(false)
    const [card2,setCard2]=useState(false)

    const [card3,setCard3]=useState(false)
    const [card4,setCard4]=useState(false)
    const [card5,setCard5]=useState(false)
    const [card6,setCard6]=useState(false)

  return (
    <div className='w-[100vw] relative h-[100%]  bg-white flex hero justify-center hero items-center overflow-hidden'>
      <div className="max-w-[1400px] max-lg:px-[5%]  lg:px-[7%] py-[2rem] pb-[10rem] w-[100%] ">
        <div className="w-[100%] grid lg:grid-cols-3 md:grid-cols-2 max-md:grid-cols-1 gap-8">
            <Blog card={card} setCard={setCard} img={img1}
            h={'Can you build muscle with smaller weights?'}
            d={'February 15, 2021'}
            p={`It can be hard to work out with so many distractions and difficulties keeping us from the gym. When we are able to work out,`}
            />
            <Blog card={card2} setCard={setCard2} img={img2}
            h={'Why you need a personal dietary approach'}
            d={'February 15, 2021'}
            p={`It can be hard to work out with so many distractions and difficulties keeping us from the gym. When we are able to work out,`}
            />
            <Blog card={card3} setCard={setCard3} img={img3}
            h={'48 Days body transformation with Jorge'}
            d={'February 15, 2021'}
            p={`It can be hard to work out with so many distractions and difficulties keeping us from the gym. When we are able to work out,`}
            />
         <Blog card={card4} setCard={setCard4} img={img4}
            h={'Why Breathwork Matters in Your Workout'}
            d={'February 15, 2021'}
            p={`It can be hard to work out with so many distractions and difficulties keeping us from the gym. When we are able to work out,`}
            />
             <Blog card={card5} setCard={setCard5} img={img5}
            h={'5 Tips to Build a Daily Movement Routine'}
            d={'February 15, 2021'}
            p={`It can be hard to work out with so many distractions and difficulties keeping us from the gym. When we are able to work out,`}
            />
             <Blog card={card6} setCard={setCard6} img={img6}
            h={'5-Minute Stress Reliever Bodyweight Workout'}
            d={'February 15, 2021'}
            p={`It can be hard to work out with so many distractions and difficulties keeping us from the gym. When we are able to work out,`}
            />
        </div>
      </div>
      <div className="absolute bottom-[-0.2rem]  w-[100%] ">
      <svg xmlns="http://www.w3.org/2000/svg" fill='#191919' viewBox="0 0 1000 100" preserveAspectRatio="none">
<path class="elementor-shape-fill" d="M737.9,94.7L0,0v100h1000V0L737.9,94.7z"></path>
</svg>
      </div>
    </div>
  )
}
