import React, { useState } from 'react'
import img from '../Assets/shape-green.svg'
import { motion } from 'framer-motion'
export default function History() {
    const [move,setMove]=useState(false)
  return (
    <div className='bg-white w-[100vw] flex justify-center items-center overflow-hidden'>
      <div className="max-w-[1400px] w-[100%] md:px-[10%] max-md:px-[5%] max-md:py-[2rem] md:pb-[15rem] ">
        <div className='md:flex max-md:flex max-md:flex-col-reverse w-[100%] justify-between items-center gap-10'>
        <div onMouseEnter={()=>setMove(()=>true)} onMouseLeave={()=>setMove(()=>false)}  className='md:w-[48%] relative '>
                <motion.img 
              
                src={require('../Assets/jelmer-assink-gzeTjGu3b_k-unsplash.jpg')} className='w-[100%]' alt="gym" />
            <motion.img 
               animate={{x:move?30:0}} transition={{duration:0.2,type:'tween'}} 
            src={img}
            className='absolute max-md:hidden bottom-[-55%] left-[15%]'
            alt="" />
            </div>
            <div className='md:w-[40%] flex flex-col max-md:gap-5 md:gap-14  items-start'>
                <h1 className="text-4xl leading-[2rem] font-semibold text-black uppercase hero">Our<br/>
                History</h1>
                <p className='text-sm tracking-wider'>
                We are the un-corporation. Our mission is to provide you with all the tools you need to reach your fitness goals in a clean, vibrant, engaging environment you will be proud to call your GYM. We won’t hold you hostage like other fitness clubs, you’ll want to stay because we care!
                </p>

            </div>
         
        </div>
      </div>
    </div>
  )
}
