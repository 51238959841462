import React, { useState } from "react";
import Radio from "../UI/Radio";
import { FaAngleDoubleRight } from "react-icons/fa";
import Send from "./Send";
export default function Join() {
  const [radio1, setRadio1] = useState(false);
  const [radio2, setRadio2] = useState(false);
  const [radio3, setRadio3] = useState(false);
  const [value, setValue] = useState({
    title: "",
    price: "",
  });
  const [next, setNext] = useState(false);
  const handleBack = () => {
    setNext(() => false);
  };
  const handleNext = (e) => {
    e.preventDefault();
    if (radio1 === true || radio2 || radio3) {
      setNext(() => true);
    } else {
      setNext(() => false);
      alert("Please selectany one plane");
    }
  };
  console.log(value);
  return (
    <div className="w-[100vw] relative h-[100%] pb-[4rem] flex hero justify-center items-center overflow-x-hidden bg-[#d6fb00]">
      <div className="max-w-[1400pc] gap-10 w-[100%] flex flex-col justify-center items-center px-[5%] max-md:py-[3rem] md:py-[7rem]">
        <h1 className="md:text-[80px] max-md:text-5xl text-center uppercase md:leading-[70px] font-bold">
          JOIN TODAY
        </h1>
        <div className="md:w-[20%] justify-center  max-md:w-[100%] flex gap-3 items-center">
          <div className="w-[3rem]  h-[3rem] bg-black text-white rounded-full text-xl font-bold flex items-center justify-center">
            1
          </div>
          <div className="border-t-[1px] border-gray-500 w-[40%]" />
          <div
            className={`w-[3rem] h-[3rem] ${
              next
                ? "bg-black text-white"
                : "border-black border-[2px] text-black"
            }  rounded-full text-xl font-bold flex items-center justify-center`}
          >
            2
          </div>
        </div>
        <div className="flex lg:w-[60%] items-center justify-center">
          <div className="flex flex-col gap-5 w-[100%] items-center">
            {!next && (
              <div className="w-[100%] grid md:grid-cols-3 max-md:grid-cols-1 gap-5">
                <Radio
                  radio={radio1}
                  second={setRadio2}
                  third={setRadio3}
                  setRadio={setRadio1}
                  setValue={setValue}
                  h1="One Day Pass"
                  s="$15/Per Day"
                  p="Whether you’re visiting F7 on business or are just taking your personal fitness one day at a time, we’d like to invite you to experience all that F7 has to offer. You are always Welcome!"
                />
                <Radio
                  radio={radio2}
                  setRadio={setRadio2}
                  third={setRadio3}
                  second={setRadio1}
                  setValue={setValue}
                  h1="Monthly Pass"
                  s="$90/Per Day"
                  p="Whether you’re visiting F7 on business or are just taking your personal fitness one day at a time, we’d like to invite you to experience all that F7 has to offer. You are always Welcome!"
                />
                <Radio
                  radio={radio3}
                  second={setRadio2}
                  third={setRadio1}
                  setRadio={setRadio3}
                  setValue={setValue}
                  h1="Yearly Pass"
                  s="$59/Per Day"
                  p="Whether you’re visiting F7 on business or are just taking your personal fitness one day at a time, we’d like to invite you to experience all that F7 has to offer. You are always Welcome!"
                />
              </div>
            )}
            {next && <Send value={value} next={next} setNext={setNext} />}
           {!next && <div className="flex w-[100%] items-center justify-between ">
              <div>
                {next && (
                  <h1
                    onClick={handleBack}
                    className="text-xl cursor-pointer uppercase font-bold tracking-wide underline text-gray-600"
                  >
                    Back
                  </h1>
                )}
              </div>
              <button
                onClick={handleNext}
                className="flex items-center hover:underline gap-2 cursor-pointer text-xl font-bold rounded-full  transition-all duration-300 ease-in-out"
              >
                <FaAngleDoubleRight className="text-5xl " /> <h1>Continue</h1>
              </button>
            </div>}
          </div>
        </div>
      </div>
      <div className="absolute bottom-[-0.2rem] w-[100%] ">
      <svg xmlns="http://www.w3.org/2000/svg" fill='#191919' viewBox="0 0 1000 100" preserveAspectRatio="none">
<path class="elementor-shape-fill" d="M737.9,94.7L0,0v100h1000V0L737.9,94.7z"></path>
</svg>
      </div>
    </div>
  );
}
